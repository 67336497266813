<template>
  <section class="dark:bg-gray-900">
    <div class="grid max-w-screen-xl px-0 py-0 mx-auto lg:gap-8 xl:gap-0 lg:pt-12 grid-cols-1 lg:grid-cols-12">
      <div v-if="trainingData" class="px-4 mr-auto place-self-center col-span-full lg:col-span-7">
        <h1
          class="max-w-2xl mb-8 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
          {{ trainingData.title }}</h1>
      </div>
    </div>
  </section>
  <!-- <div v-if="collectedResponse.length !== 0 && collectedResponse[0].trainingId === trainingData._id"
    class="max-w-screen-xl mx-auto px-4 mb-10 dark:text-white">
    <h1 class="text-4xl">Ditt senaste resultat</h1>
    <span class="mb-3 inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
      <Icon class="w-3 h-3 me-2" icon="ph-warning-circle" />
      OBS, denna vy förvinner om sidan uppdateras!
    </span>
    <div v-for="(res, index) in collectedResponse">
      Fråga {{ index + 1 }}
      {{ res.correct }}
    </div>
  </div> -->
  <div class="relative overflow-x-auto shadow mb-10">
    <table class="w-full divide-y divide-gray-200 dark:divide-gray-600">
      <thead class="bg-gray-100 dark:bg-gray-700">
        <tr>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Datum
          </th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Antal svar
          </th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Godkänd?
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
        <tr v-for="(stat, index) in statsForThisTraining" :key="index" class="hover:bg-gray-100 dark:hover:bg-gray-950">
          <td class="">
            <div class="ms-3 ps-3">
              <div class="text-base font-semibold dark:text-white">{{ getTimestamps(stat).date }}</div>
              <div class="font-normal text-gray-500 dark:text-gray-400">{{ getTimestamps(stat).time }}</div>
            </div>
            <div class="text-base font-semibold text-gray-900 dark:text-white">
            </div>
          </td>
          <td class="w-full min-w-48 p-4 text-base font-medium text-gray-900 dark:text-white">
            <div class="w-full relative my-2">
              <div :style="`left:${this.trainingData.approved_threshold.toString()}%`"
                class="absolute text-sm -top-3/4 -translate-x-1/2 text-center">
                <p class="text-sm text-gray-700 dark:text-gray-400">Godkändsgräns</p>
                <div class="m-auto w-px h-5 border-l border-white border-dashed"></div>
                <p class="mt-1 text-xs text-gray-700 dark:text-gray-400">{{ this.trainingData.approved_threshold }}%</p>
              </div>
              <div class="flex h-6 w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div v-if="percentCorrect(stat) !== 0"
                  class="h-6 inline-flex justify-center items-center bg-green-600 text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
                  :style="`width: ${percentCorrect(stat)}%`"> {{ percentCorrect(stat) }}%</div>
                <div v-if="percentWrong(stat) !== 0"
                  class="h-6 inline-flex justify-center items-center bg-red-600 text-xs font-medium text-red-100 text-center p-0.5 leading-none rounded-full"
                  :style="`width: ${percentWrong(stat)}%`"> {{ percentWrong(stat) }}%</div>
              </div>
              <!-- <apexchart type="bar" :options="getChartOptions(stat)" :series="getSeries(stat)" /> -->
            </div>
            <div class="flex justify-center pt-5">
              <span v-if="percentCorrect(stat) !== 0"
                class="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3"><span
                  class="flex w-2.5 h-2.5 bg-green-600 rounded-full me-1.5 flex-shrink-0"></span>
                {{ getCorrectAnswers(stat)[0] }} rätt svar
              </span>
              <span v-if="percentWrong(stat) !== 0"
                class="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3"><span
                  class="flex w-2.5 h-2.5 bg-red-600 rounded-full me-1.5 flex-shrink-0"></span>
                {{ getWrongAnswers(stat)[0] }} fel svar
              </span>

            </div>
          </td>
          <td>
            <span v-if="isTrainingApproved(stat)"
              class="flex bg-green-100 text-green-800 text-sm font-medium me-10 px-3 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
              <Icon class="w-5 h-5 mr-2" icon="ph-check-circle" />
              Godkänd
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { getTraining, fetchSectionsByTraining, fetchUserResponsesForUser } from "@/api/index.js";
import { useUserReponse } from '@/store/responses.js';
import { useUserStore } from "@/store/user";

export default {
  props: {
    training: String,
  },
  emits: ['scrollToTop'],
  data() {
    return {
      training_shorturl: this.$route.params.training,
      trainingData: {},
      sections: [],
      numberOfAllQuestions: 0,
      allUserStats: [],
      listQuestions: [],
      howManyTrainingsDone: 0,
    };
  },
  components: {
    Icon,
  },
  mounted() {
    this.fetchTraining();
  },
  computed: {
    statsForThisTraining() {
      const statsForThisTraining = this.allUserStats.filter(stat => stat.training_section === this.trainingData._id)
      return statsForThisTraining.reverse();
    },
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    amountOfTotalQuestions() {
      return useUserReponse().activeTraining.amountOfQuestions;
    },
    user() {
      return useUserStore().user;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue._id) {
          if (useUserReponse().userResponse.response.length === 0) {
            this.getAllStats();
          }
        }
      }
    }
  },
  methods: {
    async fetchTraining() {
      try {
        const response = await getTraining(this.training_shorturl);
        this.trainingData = response;
        this.fetchCourses();
      } catch (error) {
        console.error('Error fetching training:', error);
      }
    },
    async fetchCourses() {
      try {
        this.sections = await fetchSectionsByTraining(this.trainingData._id);
        this.totalQuestions();
        this.getAllStats();
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    },
    async getAllStats() {
      try {
        this.howManyTrainingsDone = 0
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
        this.allUserStats.forEach((stat) => {
          if (stat.training_section === this.trainingData._id) {
            this.howManyTrainingsDone++
          }
        })
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    totalQuestions() {
      this.numberOfAllQuestions = 0
      this.sections.forEach(s => {
        s.sub_sections.forEach(ss => {
          if (ss.type === 'questions') {
            ss.data.questions.forEach(question => {
              this.listQuestions.push(question);
              this.numberOfAllQuestions++;
            });
          }
        })
      });
    },
    chartDataAvailable(stat) {
      return !!(stat && stat.responses);
    },
    getChartOptions(stat) {
      // Return chart options based on the current stat
      return {
        series: this.getSeries(stat),
        chart: {
          type: "bar",
          height: 110,
          width: '100%',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
          foreColor: '#9CA3AF',
        },
        plotOptions: {
          bar: {
            borderRadius: 15,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
            horizontal: true,
          },
        },
        stroke: {
          width: 0,
          colors: ['#fff']
        },
        legend: {
          show: true,
          position: "bottom",
          labels: {
            colors: '#9CA3AF',
          },
          onItemClick: {
            toggleDataSeries: false
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          formatter: function (value) {
            return value;
          }
        },
        xaxis: {
          categories: [''],
          labels: {
            formatter: function (val) {
              return val + "%"
            },
          },
          axisBorder: {
            show: false,
          },
        },
        grid: {
          show: false,
          strokeDashArray: 1,
          padding: {
            left: 2,
            right: 2,
            top: -20
          },
        },
        fill: {
          opacity: 1,
        },
        annotations: {
          xaxis: [
            {
              x: this.trainingData.approved_threshold,
              borderColor: '#fff',
              borderWidth: 2,
              strokeDashArray: 2,
              label: {
                borderColor: this.isTrainingApproved(stat) ? '#31C48D' : '#F05252',
                orientation: 'horizontal',
                text: 'Godkändgräns',
                style: {
                  color: "#FFF",
                  background: this.isTrainingApproved(stat) ? '#31C48D' : '#F05252',
                },
              }
            }
          ]
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            }
          },
          active: {
            filter: {
              type: 'none',
              value: 0,
            }
          },
        },
      };
    },
    getSeries(stat) {
      // Return chart series based on the current stat
      return [
        {
          name: "Rätt svar",
          data: this.getCorrectAnswers(stat),
          color: "#31C48D",
        },
        {
          name: "Fel svar",
          data: this.getWrongAnswers(stat),
          color: "#F05252",
        }
      ];
    },
    getTimestamps(stat) {
      const date = new Date(stat.timestamp);
      const formattedDay = date.getDate();
      const formattedMonth = new Intl.DateTimeFormat('sv-SE', { month: 'long' }).format(date);
      const currentYear = new Date().getFullYear();
      const formattedYear = date.getFullYear() === currentYear ? '' : ` ${date.getFullYear()}`;
      const dateString = `${formattedDay} ${formattedMonth}${formattedYear}`;

      // Format time
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const timeString = `${hours}:${minutes}`;

      return { date: dateString, time: timeString };
    },
    percentCorrect(stat) {
      return Math.round(this.getCorrectAnswers(stat) / this.numberOfAllQuestions * 100);
    },
    percentWrong(stat) {
      return Math.round(this.getWrongAnswers(stat) / this.numberOfAllQuestions * 100);
    },
    getCorrectAnswers(stat) {
      // Return the count of correct answers
      return [stat.responses.filter(response => response.correct.includes(true)).length];
    },
    getWrongAnswers(stat) {
      // Return the count of wrong answers
      return [stat.responses.filter(response => response.correct.includes(false)).length];
    },
    isTrainingApproved(stat) {
      if (stat.responses.length !== 0) {
        let maxCorrect = 0;
        let totalQuestions = 0;
        stat.responses.forEach(response => {
          totalQuestions++;
          if (response.correct[0]) {
            maxCorrect++;
          }
        });
        const bestPercentage = totalQuestions === 0 ? 0 : (maxCorrect / totalQuestions) * 100;
        return bestPercentage >= this.trainingData.approved_threshold;
      } else {
        return false;
      }
    },
  }
};
</script>