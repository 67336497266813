<template>
  <!-- TEXT -->
  <div class="flex flex-col py-8 px-4 mx-auto max-w-screen-xl">
    <article
      class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
      <header class="mb-4 lg:mb-6 not-format">
        <h1 :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
          @blur="e => { editedTextSection.texts.header = e.currentTarget.textContent; textEdited(); }"
          v-html="isEditable ? editedTextSection.texts.header || 'Skriv en titel' : textData.texts.header || 'Ingen huvudrubrik'"
          :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
          class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
        </h1>
      </header>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedTextSection.texts.lead = e.currentTarget.textContent; textEdited(); }"
        v-html="isEditable ? editedTextSection.texts.lead || 'Skriv en underrubrik' : textData.texts.lead || 'Ingen undertext'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="lead">
      </p>
      <div v-if="isEditable && !makeEditable" v-for="(text, index) in editedTextSection.texts.texts" :key="index"
        class="relative group">
        <p :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
          @click="makeEditableFunc(true)" @focusout="makeEditableFunc(false)" v-html="text"></p>
        <div class="invisible absolute top-1/2 -right-16 -translate-y-1/2 group-hover:visible h-full flex items-center">
          <button type="button" @click="deleteText(index)"
            class="ml-5 text-blue-700 hover:border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
            <Icon class="w-5 h-5" icon="ph-x-bold" />
            <span class="sr-only">Delete</span>
          </button>
        </div>
      </div>
      <p v-if="isEditable && makeEditable" v-for="(text, index) in editedTextSection.texts.texts" :key="index"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        contenteditable="true" @click="makeEditableFunc(true)" @focusout="makeEditableFunc(false)"
        @blur="updateText(index, $event)">
        {{ text }}
      </p>
      <p v-if="!isEditable" v-for="(text) in textData.texts.texts" v-html="text"></p>
      <div v-if="isEditable" class="flex w-full justify-center my-10">
        <button @click="addDefaultText"
          class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
          Lägg till textfält</button>
      </div>
      <div>
        <!-- <input v-if="isEditable" type="url" name="mediaurl" id="mediaurl" v-model="editedTextSection.texts.media_url"
          @input="checkImageUrl" @blur="textEdited();"
          class="mb-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder="URL till bild" required> -->
        <PexelsComponent :isEditable="isEditable" :subSectionId="subSectionId" :editedData="editedTextSection.texts"
          @is-edited="setEdited" />
        <div v-if="!textData.texts.media_url && !editedTextSection.texts.media_url">Ingen bild</div>
        <img v-else class="mx-auto h-auto w-full lg:max-w-xl rounded-lg shadow-xl"
          :src="isEditable ? editedTextSection.texts.media_url : textData.texts.media_url" alt="image description">
      </div>
    </article>
  </div>
  <!-- END TEXT -->
  <div class="flex flex-row gap-2 grow justify-center group">
    <button v-if="isEdited" @click="textDataToEdit"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button">Återställ
    </button>
    <button v-if="isEdited" @click="updateTextSection"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button">Spara
    </button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite } from 'flowbite'
import { editSubSection } from "@/api/index.js";
import PexelsComponent from '@/components/PexelsComponent.vue';

export default {
  props: {
    sectionId: String,
    subSectionId: String,
    textData: Object,
    isEditable: Boolean,
  },
  data() {
    return {
      editedTextSection: {
        texts: {
          _id: null,
          layout: "",
          media_url: "",
          header: "",
          lead: "",
          texts: [],
        },
      },
      isEdited: false,
      isValidUrl: false,
      makeEditable: false,
    }
  },
  components: {
    Icon,
    PexelsComponent
  },
  mounted() {
    initFlowbite();
    if (this.isEditable) {
      this.textDataToEdit();
      this.editedTextSection.texts.texts.length === 0 ? this.editedTextSection.texts.texts.push("Default text") : null;
    }
    if (this.editedTextSection.texts.media_url) this.isValidUrl = true;
  },
  methods: {
    async updateTextSection() {
      try {
        await editSubSection(this.sectionId, this.subSectionId, this.editedTextSection);
        this.isEdited = false;
      } catch (error) {
        console.error("Error updating section:", error);
      }
    },
    textDataToEdit() {
      this.isEdited = false;
      this.editedTextSection.texts.header = this.textData.texts.header;
      this.editedTextSection.texts.lead = this.textData.texts.lead;
      this.editedTextSection.texts.media_url = this.textData.texts.media_url;
      this.textData.texts.texts.forEach((text, i) => {
        this.editedTextSection.texts.texts[i] = text;
      });
    },
    textEdited() {
      if (this.editedTextSection.texts.header !== this.textData.texts.header ||
        this.editedTextSection.texts.lead !== this.textData.texts.lead ||
        this.editedTextSection.texts.media_url !== this.textData.texts.media_url ||
        this.editedTextSection.texts.texts !== this.textData.texts.texts) {
        this.isEdited = true;
      } else {
        this.isEdited = false;
      }
    },
    checkImageUrl() {
      const imageExtensionsRegex = /\.(jpeg|jpg|png)/i;
      this.isValidUrl = imageExtensionsRegex.test(this.editedTextSection.texts.media_url);
    },
    makeEditableFunc(val) {
      // contenteditable = true makes the whole div clickable, which isnt good UX, this is a workaround
      this.makeEditable = val
    },
    addDefaultText() {
      this.editedTextSection.texts.texts.push("Tom text");
    },
    updateText(index, event) {
      this.editedTextSection.texts.texts[index] = event.currentTarget.textContent;
      this.textEdited();
    },
    deleteText(index) {
      this.editedTextSection.texts.texts.splice(index, 1);
      this.textEdited();
    },
    setEdited(val) {
      this.isEdited = val;
    }
  }
}
</script>