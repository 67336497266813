import axios from "axios";
import { defineStore } from "pinia";
import { getUser, loginUser } from "@/api/index.js";
import defaultImage from "@/assets/img/default.png";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    user: {},
    token: "",
    profileImage: null,
  }),
  actions: {
    async login(email, password, azure) {
      try {
        const userData = await loginUser(email, password, azure);
        // Update user and token states
        this.user = userData.user;
        this.token = userData.token;
        if (azure && userData.user.azure_ad.profile_image) {
          try {
            const bufferData = userData.user.azure_ad.profile_image.data;
            if (!bufferData || !Array.isArray(bufferData)) {
              console.error("Invalid profile image data");
              return;
            }
            const uint8Array = new Uint8Array(bufferData);
            const base64String = btoa(
              String.fromCharCode.apply(null, uint8Array)
            );
            const profileImageUrl = `data:image/jpeg;base64,${base64String}`;
            this.profileImage = profileImageUrl;
          } catch (error) {
            console.error("Error decoding profile image:", error);
          }
        } else {
          this.profileImage = defaultImage;
        }
        // Save token to localStorage for persistence
        localStorage.setItem("token", userData.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;

        return { success: true, user: userData.user };
      } catch (error) {
        // Handle login error
        console.error("Login failed:", error);
        return { success: false, message: error.response.data.message };
      }
    },
    logout() {
      this.user = {};
      this.token = "";
      this.profileImage = null;
      localStorage.removeItem("token");
    },
    async getUser() {
      try {
        const userData = await getUser();
        this.user = userData;
        if (userData && userData.azure_ad && userData.azure_ad.profile_image) {
          try {
            const bufferData = userData.azure_ad.profile_image.data;
            if (!bufferData || !Array.isArray(bufferData)) {
              console.error("Invalid profile image data");
              return;
            }
            const uint8Array = new Uint8Array(bufferData);
            const base64String = btoa(
              String.fromCharCode.apply(null, uint8Array)
            );
            const profileImageUrl = `data:image/jpeg;base64,${base64String}`;
            this.profileImage = profileImageUrl;
          } catch (error) {
            console.error("Error decoding profile image:", error);
          }
        } else {
          this.profileImage = defaultImage;
        }
        return { success: true, user: userData };
      } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
      }
    },
  },
});
