<template class="relative">
  <!-- TOP NAV -->
  <div id="marketing-banner" tabindex="-1"
    class="md:fixed w-[calc(100%-2rem)] md:w-[calc(100%-18rem)] flex flex-row justify-between m-4 p-4 backdrop-blur-md backdrop-saturate-200 bg-white/80 border border-gray-100 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700 z-20">
    <div class="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
      <router-link :to="`/admin/utbildningar/${this.training_shorturl}`"
        class="inline-flex items-center justify-center mr-4 py-2 px-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
        <Icon class="w-5 h-5 mr-2" icon="ph-arrow-left" />
        Tillbaka
      </router-link>
      <p class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
        <span class="self-center text-lg font-bold whitespace-nowrap dark:text-white">{{ this.trainingData.title
          }}</span>
      </p>
      <p class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
        <span class="self-center text-md text-gray-500 font-semibold whitespace-nowrap dark:text-white">{{
        this.section.title
      }}</span>
      </p>
      <div class="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
        <ol v-if="Object(this.section.order).length > 1"
          class="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
          <li v-for="(sectionKey, index) in section.order" :key="index"
            :class="[currentSectionIndex === index ? `${sectionTypes[sectionKey].color}` : '']"
            class="flex items-center space-x-2.5 rtl:space-x-reverse">
            <span
              class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-blue-500">
              {{ index + 1 }}
            </span>
            <span>
              <h3 class="font-medium leading-tight"> {{ sectionTypes[sectionKey].title }}</h3>
            </span>
          </li>
        </ol>
      </div>
    </div>
    <div class="flex items-center flex-shrink-0">
      <div class="text-xs bg-green-400 rounded-full text-white px-4 py-1.5">Redigeringläge</div>
    </div>
  </div>

  <SubSections :sectionData="this.section" :isEditable="true" :acceptOnly="this.trainingData.accept_only"></SubSections>

</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite } from 'flowbite'
import { getTraining, fetchSectionsByTraining, getSectionTypes } from "@/api/index.js";

import SubSections from '@/components/SubSections.vue';

export default {
  props: {
    training: String,
    shorturl: String
  },
  emits: ['scrollToTop'],
  data() {
    return {
      training_shorturl: this.$route.params.training,
      section_short_url: this.$route.params.shorturl,
      all_training_sections: [],
      section: {},
      trainingData: {},
      sectionTypes: {},
      currentSection: null,
      currentSectionIndex: 0,
      currentSubSection: null,
    };
  },
  components: {
    Icon, SubSections
  },
  mounted() {
    initFlowbite();
    this.fetchSectionTypes();
    this.fetchTrainingSections();
  },
  watch: {
    '$route.params.shorturl'(newId) {
      this.section_short_url = newId;
      this.fetchTrainingSections();
    },
  },
  computed: {
    isFirstOrder() {
      return this.currentSectionIndex === 0;
    },
    isLastOrder() {
      return this.currentSectionIndex === this.section.order.length - 1;
    },
  },
  methods: {
    async fetchTrainingSections() {
      try {
        await this.fetchTraining();
        this.all_training_sections = await fetchSectionsByTraining(this.trainingData._id);
        this.section = this.all_training_sections.find(c => c.short_url === this.section_short_url);
        this.currentSection = this.all_training_sections.findIndex(c => c.short_url === this.section_short_url);
        if (this.currentSection === -1) {
          this.currentSection = 0;
          if (this.all_training_sections.length > 0) {
            this.$router.push({ name: 'TrainingSection', params: { shorturl: this.all_training_sections[0].short_url } });
          }
        }
        this.currentSubSection = this.section.sub_sections[0];
        const currentIndex = this.section.sub_sections.indexOf(this.currentSubSection);
        if (currentIndex !== -1) {
          this.currentSectionIndex = currentIndex;
        }
      } catch (error) {
        console.error('Error fetching training:', error);
        this.$router.push('/utbildningar/');
      }
    },
    async fetchTraining() {
      try {
        const response = await getTraining(this.training_shorturl);
        this.trainingData = response;
      } catch (error) {
        console.error('Error fetching training:', error);
      }
    },
    async fetchSectionTypes() {
      try {
        const response = await getSectionTypes();
        this.sectionTypes = response;
      } catch (error) {
        console.error('Error fetching section types:', error);
      }
    },
    navigatePrevious() {
      if (this.currentSection > 0) {
        this.currentSection--;
        this.navigateToSection();
      }
    },
    navigateNext() {
      if (this.currentSection < this.all_training_sections.length - 1) {
        this.currentSection++;
        this.navigateToSection();
      }
    },
    orderPrevious() {
      if (this.currentSectionIndex > 0) {
        this.currentSectionIndex--;
        this.currentSubSection = this.section.order[this.currentSectionIndex];
        this.navigateToOrder();
      }
    },
    orderNext() {
      if (this.currentSectionIndex < this.section.order.length - 1) {
        this.currentSectionIndex++;
        this.currentSubSection = this.section.order[this.currentSectionIndex];
        this.navigateToOrder();
      }
    },
    navigateToSection() {
      const section = this.all_training_sections[this.currentSection];
      if (section) {
        this.$router.push({ name: 'TrainingSection', props: { shorturl: section.short_url } });
      }
      this.$emit('scrollToTop');
    },
    navigateToOrder() {
      this.$emit('scrollToTop');
    },
  }
};
</script>

<style scoped>
.bottomNav {
  width: 100vw;
}

@media (min-width: 768px) {
  .bottomNav {
    width: calc(100vw - 256px);
  }
}
</style>