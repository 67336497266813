import { defineStore } from "pinia";

export const useUserReponse = defineStore({
  id: "userresponse",
  state: () => ({
    activeTraining: {
      trainingId: "",
      amountOfQuestions: 0,
    },
    userResponse: {
      trainingId: "",
      response: [],
    },
  }),
});
