<template>
  <div class="flex flex-col flex-none items-center justify-center group">
    <!-- LAYOUT DROPDOWN -->
    <button v-if="isEditable" :id="`dropdown-button-video`" @click="toggleSectionDropdown('video')"
      class="justify-between z-10 inline-flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-center text-sm rounded-lg border-gray-100 dark:border-gray-700 border-2 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      type="button">
      {{ editedVideoSection.layout }} <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
      </svg>
    </button>
    <div :id="`dropdown-layout-video`"
      class="hidden mt-1 z-20 bg-white rounded-lg border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600">
      <div class="w-full px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6">
        <h3 class="mb-5 text-lg font-medium text-gray-900 dark:text-white">Välj layoutstyp</h3>
        <ul class="grid gap-6 md:grid-cols-3">
          <li class="w-52">
            <input @change="videoEdited" type="radio" id="video-layout1-option" name="layouttype" value="Layout 1"
              class="hidden peer" v-model="editedVideoSection.layout" required="">
            <label for="video-layout1-option"
              class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div class="block w-full">
                <div class="flex w-fit border m-auto p-5 mb-2 w-32 h-32 justify-center">
                  <Icon class="w-10 h-10" icon="ph-text-align-left" />
                  <Icon class="w-10 h-10" icon="ph-video" />
                </div>
                <div class="w-full text-lg font-semibold">Layout 1</div>
                <div class="w-full text-sm">Klassisk layout</div>
              </div>
            </label>
          </li>
          <li class="w-52">
            <input @change="videoEdited" type="radio" id="video-layout2-option" name="layouttype" value="Layout 2"
              class="hidden peer" v-model="editedVideoSection.layout" required="">
            <label for="video-layout2-option"
              class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div class="block w-full">
                <div class="flex w-fit border m-auto p-5 mb-2 w-32 h-32 jusitfy-center">
                  <Icon class="w-10 h-10" icon="ph-video" />
                  <Icon class="w-10 h-10" icon="ph-text-align-center" />
                </div>
                <div class="w-full text-lg font-semibold">Layout 2</div>
                <div class="w-full text-sm">Omvänd klassisk</div>
              </div>
            </label>
          </li>
          <li class="w-52">
            <input @change="videoEdited" type="radio" id="video-layout3-option" name="layouttype" value="Layout 3"
              class="hidden peer" v-model="editedVideoSection.layout" required="">
            <label for="video-layout3-option"
              class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div class="block w-full">
                <div class="flex w-fit border m-auto p-5 mb-2 w-32 h-32 justify-center">
                  <Icon class="w-14 h-14" icon="ph-video" />
                </div>
                <div class="w-full text-lg font-semibold">Layout 3</div>
                <div class="w-full text-sm">Stor video och text under</div>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- END DROPDOWN -->
  <!-- LAYOUT 1 -->
  <div v-if="!isEditable ? videoData.layout === 'Layout 1' : editedVideoSection.layout === 'Layout 1'"
    class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
    <div class="flex flex-col justify-center">
      <h1 :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.title = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.title || 'Skriv en titel' : videoData.title || 'Ingen titel'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
      </h1>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.subtitle = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.subtitle || 'Skriv en underrubrik' : videoData.subtitle || 'Ingen underrubrik'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-4 text-lg font-semibold text-gray-500 lg:text-2xl dark:text-gray-400">
      </p>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.description = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.description || 'Skriv en beskrivning' : videoData.description || 'Ingen beskrivning'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-8 text-lg font-light text-gray-500 lg:text-lg dark:text-gray-400">
      </p>
    </div>
    <div>
      <input v-if="isEditable" type="url" name="mediaurl" id="mediaurl" v-model="editedVideoSection.media_url"
        @input="checkVideoUrl" @blur="videoEdited();"
        class="mb-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        placeholder="Video/Bild url" required>
      <div v-if="!videoData.media_url && !isValidUrl">Ingen video</div>
      <iframe v-else class="mx-auto w-full lg:max-w-xl rounded-lg shadow-xl aspect-video"
        :src="isEditable ? editedVideoSection.media_url : videoData.media_url" title="Vimeo video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>
  <!-- LAYOUT 2 -->
  <div v-if="!isEditable ? videoData.layout === 'Layout 2' : editedVideoSection.layout === 'Layout 2'"
    class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
    <div>
      <input v-if="isEditable" type="url" name="mediaurl" id="mediaurl" v-model="editedVideoSection.media_url"
        @input="checkVideoUrl" @blur="videoEdited();"
        class="mb-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        placeholder="Video/Bild url" required>
      <div v-if="!videoData.media_url && !isValidUrl">Ingen video</div>
      <iframe v-else class="mx-auto w-full lg:max-w-xl rounded-lg shadow-xl aspect-video"
        :src="isEditable ? editedVideoSection.media_url : videoData.media_url" title="Vimeo video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="flex flex-col justify-center">
      <h1 :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.title = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.title || 'Skriv en titel' : videoData.title || 'Ingen titel'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
      </h1>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.subtitle = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.subtitle || 'Skriv en underrubrik' : videoData.subtitle || 'Ingen underrubrik'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-4 text-lg font-semibold text-gray-500 lg:text-2xl dark:text-gray-400">
      </p>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.description = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.description || 'Skriv en beskrivning' : videoData.description || 'Ingen beskrivning'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-8 text-lg font-light text-gray-500 lg:text-lg dark:text-gray-400">
      </p>
    </div>
  </div>
  <!-- LAYOUT 3 -->
  <div v-if="!isEditable ? videoData.layout === 'Layout 3' : editedVideoSection.layout === 'Layout 3'"
    class="px-4 mx-auto max-w-screen-xl">
    <div>
      <input v-if="isEditable" type="url" name="mediaurl" id="mediaurl" v-model="editedVideoSection.media_url"
        @input="checkVideoUrl" @blur="videoEdited();"
        class="mt-5 mb-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        placeholder="Video/Bild url" required>
      <div v-if="!videoData.media_url && !isValidUrl">Ingen video</div>
      <iframe v-else class="mx-auto w-full rounded-lg shadow-xl aspect-video"
        :src="isEditable ? editedVideoSection.media_url : videoData.media_url" title="Vimeo video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="flex flex-col justify-center pt-14">
      <h1 :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.title = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.title || 'Skriv en titel' : videoData.title || 'Ingen titel'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
      </h1>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.subtitle = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.subtitle || 'Skriv en underrubrik' : videoData.subtitle || 'Ingen underrubrik'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-4 text-lg font-semibold text-gray-500 lg:text-2xl dark:text-gray-400">
      </p>
      <p :contenteditable="makeEditable" @click="makeEditableFunc(isEditable)" @focusout="makeEditableFunc(false)"
        @blur="e => { editedVideoSection.description = e.currentTarget.textContent; videoEdited(); }"
        v-html="isEditable ? editedVideoSection.description || 'Skriv en beskrivning' : videoData.description || 'Ingen beskrivning'"
        :class="[isEditable ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8` : '']"
        class="mb-8 text-lg font-light text-gray-500 lg:text-lg dark:text-gray-400">
      </p>
    </div>
  </div>
  <!-- END LAYOUTS -->
  <div class="flex flex-row gap-2 grow justify-center group">
    <button v-if="isEdited" @click="(videoDataToEdit)"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button">Återställ
    </button>
    <button v-if="isEdited" @click="updateVideoSection"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button">Spara
    </button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite, Dropdown } from 'flowbite'
import { editSubSection } from "@/api/index.js";

export default {
  props: {
    sectionId: String,
    subSectionId: String,
    videoData: Object,
    isEditable: Boolean,
  },
  data() {
    return {
      editedVideoSection: {
        layout: "",
        title: "",
        subtitle: "",
        description: "",
        media_url: "",
      },
      isEdited: false,
      isValidUrl: false,
      makeEditable: false,
      dropSection: null,
    }
  },
  components: {
    Icon
  },
  mounted() {
    initFlowbite();
    if (this.isEditable) { this.videoDataToEdit(); }
    if (this.editedVideoSection.media_url) this.isValidUrl = true;
  },
  methods: {
    async updateVideoSection() {
      try {
        await editSubSection(this.sectionId, this.subSectionId, this.editedVideoSection);
      } catch (error) {
        console.error("Error updating section:", error);
      }
    },
    videoDataToEdit() {
      this.isEdited = false;
      this.editedVideoSection.title = this.videoData.title;
      this.editedVideoSection.subtitle = this.videoData.subtitle;
      this.editedVideoSection.description = this.videoData.description;
      this.editedVideoSection.layout = this.videoData.layout;
      this.editedVideoSection.media_url = this.videoData.media_url;
    },
    videoEdited() {
      if (this.editedVideoSection.title !== this.videoData.title ||
        this.editedVideoSection.subtitle !== this.videoData.subtitle ||
        this.editedVideoSection.description !== this.videoData.description ||
        this.editedVideoSection.layout !== this.videoData.layout ||
        this.editedVideoSection.media_url !== this.videoData.media_url) {
        this.isEdited = true;
      } else {
        this.isEdited = false;
      }
    },
    checkVideoUrl() {
      const vimeoUrlPattern = /^https:\/\/player\.vimeo\.com\/video\/\d+(\?h=[a-zA-Z0-9]+)?$/;
      this.isValidUrl = vimeoUrlPattern.test(this.editedVideoSection.media_url);
    },
    makeEditableFunc(val) {
      // contenteditable = true makes the whole div clickable, which isnt good UX, this is a workaround
      this.makeEditable = val
    },
    toggleSectionDropdown(section) {
      const targetEl = document.getElementById(`dropdown-layout-${section}`);
      const triggerEl = document.getElementById(`dropdown-button-${section}`);
      const instanceOptions = {
        id: `dropdown-layout-${section}`,
        override: true
      };
      this.dropSection = new Dropdown(targetEl, triggerEl, instanceOptions);
      this.dropSection.toggle();
    },
  }
}
</script>