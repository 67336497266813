import { defineStore } from "pinia";

export const msStore = defineStore({
  id: "msStore",
  state: () => ({
    msalConfig: {
      auth: {
        clientId: "b5e79c04-2625-4b11-9547-c21c727251ed",
        authority:
          "https://login.microsoftonline.com/b6437202-355e-4716-85f8-0a0594a167e5",
      },
      cache: {
        cacheLocation: "localStorage",
      },
    },
    accessToken: "",
  }),
  actions: {
    setAccessToken(token) {
      this.accessToken = token;
    },
  },
});

export const useSidebarStore = defineStore({
  id: "sidebar",
  state: () => ({
    sidebar: null,
    sidebarVisible: false,
  }),
  actions: {
    setSidebar(sidebar) {
      this.sidebar = sidebar;
    },
    setSidebarState(state) {
      this.sidebarVisible = state;
    },
  },
});
