<template class="relative">
  <!-- TOP NAV -->
  <div id="marketing-banner" tabindex="-1"
    class="md:fixed w-[calc(100%-2rem)] md:w-[calc(100%-18rem)] flex flex-row justify-between m-4 p-4 backdrop-blur-md backdrop-saturate-200 bg-white/80 border border-gray-100 rounded-lg shadow-sm dark:bg-gray-800/80 dark:border-gray-700 z-10">
    <div class="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
      <router-link :to="`/utbildningar/${training_shorturl}`"
        class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
        <span class="self-center text-lg font-bold whitespace-nowrap dark:text-white">{{ this.trainingData.title
          }}</span>
      </router-link>
      <p class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
        <span class="self-center text-md text-gray-500 font-semibold whitespace-nowrap dark:text-white">{{
        this.section.title
      }}</span>
      </p>
    </div>
    <div v-if="isTrainingCompleted(this.trainingData)" class="flex items-center flex-shrink-0">
      <div v-if="isTrainingApproved(this.trainingData)"
        class="text-xs bg-green-600 rounded-full text-white px-4 py-1.5">{{ this.trainingData.accept_only ? 'Godkänt' :
        'Godkänd' }}</div>
      <span v-else class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3">Ej godkänd</span>
    </div>
    <div v-else class="flex items-center flex-shrink-0">
      <span class="text-xs bg-yellow-400 rounded-full text-white px-4 py-1.5 mr-3">Ej gjord</span>
    </div>
  </div>

  <SubSections :sectionData="this.section" :amountOfQuestions="numberOfQuestionSubSections" :isEditable="false"
    :acceptOnly="this.trainingData.accept_only" @send-done="allQuestionsAreAnswered">
  </SubSections>

  <!-- BOTTOM NAV -->
  <div v-if="!onePager"
    class="fixed bottom-0 left-0 md:ml-64 bottomNav z-30 h-16 bg-white border-t border-gray-200 dark:bg-gray-800 dark:border-gray-600">
    <div class="grid grid-cols-3 h-full mx-auto">
      <div class="inline-flex flex-col flex-none items-start lg:items-end justify-center group">
        <button v-if="this.currentSection != 0" type="button" @click="navigatePrevious"
          class="px-5 hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 h-full">
          <Icon class="h-6 w-6" icon="ph-arrow-left" />
          <span class="sr-only">Föregående</span>
        </button>
      </div>
      <div class="flex flex-col items-center justify-center">
        <!-- <button id="dropdownTopButton" data-dropdown-toggle="dropdownTop" data-dropdown-placement="top"
          class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          type="button">Gå till<svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
        <div id="dropdownTop"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-auto dark:bg-gray-700">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
            <li v-for="section in sections" :key="section.id">
              <router-link :to="`${section.short_url}`"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-white">
                {{ section.title }}
              </router-link>
            </li>
          </ul>
        </div> -->
      </div>
      <div v-if="!subSectionsDone && Object(this.currentSubSection).type === 'questions'"
        class="inline-flex flex-col flex-none items-end lg:items-start justify-center">
        <button v-if="!isDone && this.currentSection < this.sections.length - 1" type="button" @click="navigateNext"
          class="flex items-center px-5 hover:bg-yellow-300 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 h-full">
          Hoppa över
          <Icon class="h-6 w-6 ml-2" icon="ph-arrow-arc-right" />
        </button>
        <div v-if="isDone && this.currentSection === this.sections.length - 1"
          class="relative flex justify-center items-center">
          <!-- <span class="h-8 w-3/4 animate-ping absolute inline-flex rounded-lg bg-green-400 opacity-75"></span> -->
          <button type="button" @click="sendUsersAnswersToDB" class="relative flex items-center py-3 px-5 rounded-lg font-medium text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300
          dark:focus:ring-primary-900">
            <Icon class="w-6 h-6 mr-2" icon="ph-check-circle" />
            Slutför
          </button>
        </div>
        <button v-if="isDone && this.currentSection < this.sections.length - 1" type="button" @click="navigateNext"
          class="flex items-center px-5 hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 h-full">
          Nästa
          <Icon class="h-6 w-6 ml-2" icon="ph-arrow-right" />
        </button>
      </div>
      <div v-else class="inline-flex flex-col flex-none items-end lg:items-start justify-center">
        <button v-if="this.currentSection < this.sections.length - 1" type="button" @click="navigateNext"
          class="flex items-center px-5 hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 h-full">
          Nästa
          <Icon class="h-6 w-6 ml-2" icon="ph-arrow-right" />
        </button>
        <div v-if="isDone && this.currentSection === this.sections.length - 1"
          class="relative flex justify-center items-center">
          <!-- <span class="h-8 w-3/4 animate-ping absolute inline-flex rounded-lg bg-green-400 opacity-75"></span> -->
          <button type="button" @click="sendUsersAnswersToDB" class="relative flex items-center py-3 px-5 rounded-lg font-medium text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300
          dark:focus:ring-primary-900">
            <Icon class="w-6 h-6 mr-2" icon="ph-check-circle" />
            Slutför
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite } from 'flowbite'
import { getTraining, fetchSectionsByTraining, getSectionTypes, saveUserResponse, fetchUserResponsesForUser } from "@/api/index.js";

import SubSections from '@/components/SubSections.vue';
import { useUserReponse } from "@/store/responses";
import { useUserStore } from "@/store/user";

export default {
  props: {
    training: String,
    shorturl: String
  },
  emits: ['scrollToTop'],
  data() {
    return {
      training_shorturl: this.$route.params.training,
      section_short_url: this.$route.params.shorturl,
      sections: [],
      section: {},
      numberOfQuestionSubSections: 0,
      numberOfAllQuestions: 0,
      trainingData: {},
      sectionTypes: {},
      currentSection: null,
      currentSectionIndex: 0,
      currentSubSection: null,
      correctAnswers: [],
      onePager: null,
      subSectionsDone: false,
      isDone: false,
      allUserStats: [],
    };
  },
  components: {
    Icon, SubSections
  },
  mounted() {
    initFlowbite();
    this.fetchSectionTypes();
    this.fetchCourses();
  },
  computed: {
    user() {
      return useUserStore().user;
    },
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    totalOfQuestions() {
      return useUserReponse().activeTraining.amountOfQuestions;
    },
    inSameTraining() {
      return useUserReponse().userResponse.trainingId === this.trainingData._id;
    },
    isFirstOrder() {
      return this.currentSectionIndex === 0;
    },
    trainingCompleted() {
      if (this.collectedResponse.length !== 0) {
        if (this.inSameTraining) {
          if (this.collectedResponse.length === this.totalOfQuestions) {
            return true
          }
          return false
        }
      }
      return false
    }
    // isLastOrder() {
    //   return this.currentSectionIndex === this.section.order.length - 1;
    // },
    // subSectionsDone() {
    //   const hasMissingIndex = this.correctAnswers.length !== this.sectionTest.questions.length || this.correctAnswers.includes(undefined);
    //   return !hasMissingIndex;
    // }
  },
  watch: {
    '$route.params.shorturl'(newId) {
      this.section_short_url = newId;
      this.fetchCourses();
    },
    trainingCompleted: {
      handler: 'setTrainingCompleted',
    },
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue._id) {
          this.getAllStats();
        }
      }
    }
  },
  methods: {
    async fetchCourses() {
      try {
        await this.fetchTraining()
        this.sections = await fetchSectionsByTraining(this.trainingData._id);
        this.section = this.sections.find(c => c.short_url === this.section_short_url);
        this.sections.length == 1 ? this.onePager = true : this.onePager = false
        this.calculateTotalQuestions();
        this.calculateQuestionsSubSection();
        useUserReponse().activeTraining.trainingId = this.trainingData._id;
        useUserReponse().activeTraining.amountOfQuestions = this.numberOfAllQuestions;
        this.currentSection = this.sections.findIndex(c => c.short_url === this.section_short_url);
        if (this.currentSection === -1) {
          this.currentSection = 0;
          if (this.sections.length > 0) {
            this.$router.push({ name: 'TrainingSection', params: { shorturl: this.sections[0].short_url } });
          }
        }
        this.currentSubSection = this.section.sub_sections[0];
        const currentIndex = this.section.sub_sections.indexOf(this.currentSubSection);
        if (currentIndex !== -1) {
          this.currentSectionIndex = currentIndex;
        }
      } catch (error) {
        console.error('Error fetching training:', error);
        this.$router.push('/utbildningar/');
      }
    },
    async fetchTraining() {
      try {
        const response = await getTraining(this.training_shorturl);
        this.trainingData = response;
      } catch (error) {
        console.error('Error fetching training:', error);
      }
    },
    async fetchSectionTypes() {
      try {
        const response = await getSectionTypes();
        this.sectionTypes = response;
      } catch (error) {
        console.error('Error fetching section types:', error);
      }
    },
    async getAllStats() {
      try {
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    isTrainingCompleted(training) {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === training._id);
      return matchingEntries.some(entry => {
        if (entry.responses.length !== 0) {
          return true;
        } else {
          return entry.accepted;
        }
      });
    },
    isTrainingApproved(training) {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === training._id);
      return matchingEntries.some(entry => {
        if (entry.responses.length !== 0) {
          let maxCorrect = 0;
          let totalQuestions = 0;
          entry.responses.forEach(response => {
            totalQuestions++;
            if (response.correct[0]) {
              maxCorrect++;
            }
          });
          const bestPercentage = totalQuestions === 0 ? 0 : (maxCorrect / totalQuestions) * 100;
          return bestPercentage >= training.approved_threshold;
        } else {
          return entry.accepted;
        }
      });
    },
    navigatePrevious() {
      if (this.currentSection > 0) {
        this.currentSection--;
        this.navigateToSection();
      }
    },
    navigateNext() {
      if (this.currentSection < this.sections.length - 1) {
        this.currentSection++;
        this.navigateToSection();
      }
    },
    orderPrevious() {
      if (this.currentSectionIndex > 0) {
        this.currentSectionIndex--;
        this.currentSubSection = this.section.sub_sections[this.currentSectionIndex];
        this.navigateToOrder();
      }
    },
    orderNext() {
      if (this.currentSectionIndex < this.section.sub_sections.length - 1) {
        this.currentSectionIndex++;
        this.currentSubSection = this.section.sub_sections[this.currentSectionIndex];
        this.navigateToOrder();
      }
    },
    navigateToSection() {
      const section = this.sections[this.currentSection];
      if (section) {
        this.$router.push({ name: 'TrainingSection', params: { shorturl: section.short_url } });
      }
      this.$emit('scrollToTop');
    },
    navigateToOrder() {
      this.$emit('scrollToTop');
    },
    allQuestionsAreAnswered(val) {
      this.subSectionsDone = val;
    },
    calculateQuestionsSubSection() {
      this.numberOfQuestionSubSections = 0;
      this.section.sub_sections.forEach(s => {
        if (s.type === 'questions') {
          s.data.questions.forEach(() => {
            this.numberOfQuestionSubSections++;
          });
        }
      });
    },
    calculateTotalQuestions() {
      this.numberOfAllQuestions = 0
      this.sections.forEach(s => {
        s.sub_sections.forEach(ss => {
          if (ss.type === 'questions') {
            ss.data.questions.forEach(() => {
              this.numberOfAllQuestions++;
            });
          }
        })
      });
    },
    setTrainingCompleted() {
      this.isDone = true;
    },
    async saveResponse(data) {
      try {
        await saveUserResponse(data)
      } catch (error) {
        console.log("Error saving response", error);
      }
    },
    sendUsersAnswersToDB() {
      const data = {
        user: useUserStore().user._id,
        training_section: useUserReponse().userResponse.trainingId,
        responses: useUserReponse().userResponse.response,
      };
      this.saveResponse(data);
      useUserReponse().userResponse.response = [];
      this.$router.push({ name: 'TrainingResult', params: { training: this.training_shorturl } });
    },
  }
};
</script>

<style scoped>
.bottomNav {
  width: 100vw;
}

@media (min-width: 768px) {
  .bottomNav {
    width: calc(100vw - 256px);
  }
}
</style>