<template>
  <!-- ACCEPT -->
  <div class="flex flex-col py-8 px-4 mx-auto max-w-screen-xl h-32">
    <div v-if="!isTrainingAccepted()" :class="[isScrollEnd ? `translate-y-0` : 'translate-y-60']"
      class="absolute bottom-14 left-2/4 -translate-x-1/2 lg:translate-x-10 transition ease-in-out delay-150">
      <button type="button" @click="saveAnsweredQuestions"
        :class="[justAccepted ? 'bg-green-700 dark:bg-green-600' : 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700']"
        :disabled="justAccepted"
        class="shadow-2xl px-6 py-3.5 text-base font-medium text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:focus:ring-blue-800">
        {{ justAccepted ? 'Accepterat' : 'Acceptera' }}
      </button>
    </div>
    <div v-if="isTrainingAccepted()" :class="[isScrollEnd ? `translate-y-0` : 'translate-y-60']"
      class="absolute bottom-10 left-2/4 -translate-x-1/2 lg:translate-x-10 transition ease-in-out delay-150">
      <div
        class="shadow-2xl px-6 py-3.5 text-base font-medium text-white bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-green-600 dark:focus:ring-blue-800">
        Redan accepterad
      </div>
    </div>
  </div>
  <!-- END ACCEPT -->
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite } from 'flowbite'
import { saveUserAcceptResponse, fetchUserResponsesForUser } from "@/api/index.js";
import { useUserStore } from '@/store/user.js';

export default {
  props: {
    trainingId: String,
    accepted: Boolean,
  },
  data() {
    return {
      isScrollEnd: false,
      allUserStats: [],
      justAccepted: false,
    }
  },
  components: {
    Icon
  },
  mounted() {
    initFlowbite();
    this.$emitter.on('scrollEnd', this.handleScrollEnd);
  },
  computed: {
    user() {
      return useUserStore().user;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue._id) {
          this.getAllStats();
        }
      }
    }
  },
  methods: {
    async saveResponse(data) {
      try {
        await saveUserAcceptResponse(data)
      } catch (error) {
        console.log("Error saving response", error);
      }
    },
    saveAnsweredQuestions() {
      const data = {
        user: this.user._id,
        training_section: this.trainingId,
        accepted: true
      };
      this.saveResponse(data);
      this.justAccepted = true;
    },
    handleScrollEnd(isEnd) {
      this.isScrollEnd = isEnd;
    },
    async getAllStats() {
      try {
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    isTrainingAccepted() {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === this.trainingId);
      return matchingEntries.some(entry => {
        return entry.accepted;
      });
    },
  },
  beforeDestroy() {
    this.emitter.off('scrollEnd', this.handleScrollEnd);
  },
}
</script>