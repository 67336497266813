<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <Header v-if="!$route.meta.hideNav" />
    <Sidebar v-if="!$route.meta.hideNav" />
    <main @scroll="handleScroll" id="stenkoll-main" ref="mainElement"
      :class="[!$route.meta.hideNav ? 'md:ml-64 dashboard overflow-auto' : '']">
      <router-view @scrollToTop="scrollMainToTop" />
    </main>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";
import { useUserStore } from '@/store/user.js';
import { useTrainingsStore } from '@/store/trainings'
import { initFlowbite } from 'flowbite'

export default {
  name: "App",
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    initFlowbite();
    // this.loadUser();
    // this.fetchAllTrainings();
  },
  components: {
    Header,
    Sidebar,
  },
  methods: {
    // async loadUser() {
    //   try {
    //     const { success, user } = await useUserStore().getUser(); // Call the getUser action from the store
    //     if (success) {
    //       this.user = user;
    //     } else {
    //       console.error('Failed to fetch user data');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching user data:', error);
    //   }
    // },
    // async fetchAllTrainings() {
    //   try {
    //     this.trainings = await useTrainingsStore().getAll();
    //   } catch (error) {
    //     console.error("Error fetching trainings:", error);
    //   }
    // },
    scrollMainToTop() {
      const mainElement = this.$refs.mainElement;
      if (mainElement) {
        setTimeout(() => {
          mainElement.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
      }
    },
    handleScroll(e) {
      const isVisible = (
        e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight - 100)
      );
      if (isVisible) {
        this.$emitter.emit('scrollEnd', true); // Emit event with true
      } else {
        this.$emitter.emit('scrollEnd', false); // Emit event with false
      }
    },
  },
};
</script>

<style scoped>
.dashboard {
  height: calc(100dvh - 60px);
}
</style>