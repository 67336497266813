import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store/user.js";

// Default Pages
import Dashboard from "../views/Dashboard.vue";
import Training from "../views/Training.vue";
import TrainingSection from "../views/TrainingSection.vue";
import TrainingResult from "../views/TrainingResult.vue";
import TrainingCertificate from "../views/TrainingCertificate.vue";

// Admin
import AdminTrainings from "../views/admin/Trainings.vue";
import AdminTraining from "../views/admin/Training.vue";
import AdminTrainingSection from "../views/admin/TrainingSection.vue";
import AdminUsers from "@/views/admin/Users.vue";
import AdminStats from "@/views/admin/Stats.vue";

// Auth
import Login from "../views/layouts/auth/Login.vue";
import Register from "../views/layouts/auth/Register.vue";
import ForgotPassword from "../views/layouts/auth/Forgot.vue";

// Layouts
import Blank from "../views/layouts/Blank.vue";

// Error page
import Page404 from "../views/layouts/error/404.vue";
import Page500 from "../views/layouts/error/500.vue";
import PageMaintenance from "../views/layouts/error/Service.vue";

var appname = " - Stenkoll";

const routes = [
  // Routes
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Dashboard " + appname, requiresAuth: true },
  },
  {
    path: "/utbildningar/:id",
    name: "Training",
    component: Training,
    meta: { title: "Utbildning " + appname, requiresAuth: true },
  },
  {
    path: "/utbildningar/:training/:shorturl",
    name: "TrainingSection",
    component: TrainingSection,
    props: true,
    meta: { title: "Utbildning " + appname, requiresAuth: true },
  },
  {
    path: "/utbildningar/:training/resultat",
    name: "TrainingResult",
    component: TrainingResult,
    props: true,
    meta: { title: "Resultat " + appname, requiresAuth: true },
  },
  {
    path: "/utbildningar/:training/diplom",
    name: "TrainingCertificate",
    component: TrainingCertificate,
    props: true,
    meta: { title: "Diplom " + appname, requiresAuth: true },
  },
  // Admin
  {
    path: "/admin/utbildningar",
    name: "AdminTrainings",
    component: AdminTrainings,
    meta: {
      title: "Admin - Utbildningar " + appname,
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/utbildningar/:id",
    name: "AdminTraining",
    component: AdminTraining,
    meta: {
      title: "Admin - Utbildning " + appname,
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/utbildningar/:training/:shorturl",
    name: "AdminTrainingSection",
    component: AdminTrainingSection,
    props: true,
    meta: {
      title: "Utbildning " + appname,
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/anvandare",
    name: "AdminUsers",
    component: AdminUsers,
    meta: {
      title: "Admin - Användare " + appname,
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/statistik",
    name: "AdminStats",
    component: AdminStats,
    meta: {
      title: "Admin - Statistik " + appname,
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  // Layouts
  {
    path: "/Blank",
    name: "Blank Page",
    component: Blank,
    meta: { title: "Blank Page" + appname },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Logga in" + appname, hideNav: true },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { title: "Register" + appname, hideNav: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: "Forgot Password" + appname, hideNav: true },
  },

  // Error
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "Våning 4, rum 4.." + appname, hideNav: true },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Server internal Error" + appname, hideNav: true },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: PageMaintenance,
    meta: {
      title: "Sorry the app is in Maintenance" + appname,
      hideNav: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  const userStore = useUserStore();

  if (to.meta.requiresAuth) {
    if (userStore.user && localStorage.getItem("token")) {
      // User is logged in, proceed with the navigation
      if (to.meta.requiresAdmin && !userStore.user.global_admin) {
        // If route requires admin access and user is not an admin, redirect to home or another route
        next("/");
      } else {
        // User is authorized, proceed with the navigation
        next();
      }
    } else if (!userStore.user && localStorage.getItem("token")) {
      // User object not loaded yet, fetch user data
      await userStore.getUser();
      next();
    } else {
      // User is not logged in, redirect to login page
      next("/login");
    }
  } else {
    // Page does not require authentication, proceed with the navigation
    next();
  }
});

export default router;
