<template>
  <div class="p-4 block sm:flex items-center justify-between">
    <div class="w-full mb-1">
      <div class="sm:flex">
        <h1 v-if="training" class="text-xl font-semibold text-gray-900 sm:text-4xl dark:text-white">
          {{ training.title }}
        </h1>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 px-4 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
    <div class="col-span-full xl:col-auto">
      <div
        class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
          <img class="mb-4 rounded-lg w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0 object-cover"
            :src="[editedTraining.image_url ? editedTraining.image_url : 'https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2']"
            alt="Jese picture">
          <div class="w-full">
            <h3 class="mb-3 text-xl font-bold text-gray-900 dark:text-white">Bakgrundsbild</h3>
            <AdminPexelsComponent :trainingId="trainingId" :editedTraining="editedTraining" @is-edited="setEdited" />
          </div>
        </div>
      </div>
      <div
        class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <h3 class="mb-4 text-xl font-semibold dark:text-white">Metadata</h3>
        <form @submit.prevent="updateTraining">
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6">
              <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
              <input type="text" name="title" id="title" v-model="editedTraining.title"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Utbildningsnamn" required>
            </div>
            <div class="col-span-6">
              <label for="message"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Underrubrik</label>
              <textarea id="message" rows="4" v-model="editedTraining.subtitle"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Kort beskrivning"></textarea>
            </div>
            <div class="col-span-6">
              <label for="message"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
              <textarea id="message" rows="4" v-model="editedTraining.description"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Kort beskrivning"></textarea>
            </div>
            <div class="col-full flex">
              <button
                class="text-white focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-800"
                type="submit" :disabled="!isEdited"
                :class="!isEdited ? 'bg-gray-300 hover:bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-600 cursor-not-allowed' : 'bg-primary-700 hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700'">
                Spara
              </button>
              <button v-if="isEdited" type="button" @click="resetEdit(training)"
                class="ml-2 text-blue-700 border border-blue-700 hover:border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-green-500 dark:hover:border-green-500">
                <Icon class="w-4 h-4" icon="ph-arrow-u-up-left" />
                <span class="sr-only">Återställ</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-span-2">
      <div
        class="mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 dark:bg-gray-800">
        <div class="flow-root">
          <h3 class="text-xl font-semibold dark:text-white pt-4 pl-4 sm:pt-6 sm: pl-6">Sektioner</h3>
          <div v-if="sections.length < 1" class="w-full my-4 p-8 md:p-12 text-center">
            <h2 class="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">Skapa din första sektion!
            </h2>
            <p class="text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">Det är ganska enkelt, om du gör fel,
              gör om bara 🤪</p>
            <div class="pb-4 pl-4 sm:pb-6 sm: pl-6">
              <button @click="toggleAddModal"
                class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Lägg
                till ny sektion</button>
            </div>
          </div>
          <table v-if="sections.length > 0"
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 my-4">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Titel
                </th>
                <th scope="col" class="px-6 py-3">
                  Moduler
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="section in sections" :key="section._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ section.title }}
                </th>
                <td class="px-6 py-4">
                  <template v-for="(sub, index) in section.sub_sections" :key="index">
                    <div v-if="getSectionType(sub.type)"
                      :class="`${getSectionType(sub.type).bg_color} ${getSectionType(sub.type).color} dark:${getSectionType(sub.type).dark_bg} dark:${getSectionType(sub.type).dark_color}`"
                      class="text-xs font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded-md">
                      <Icon class="mr-1" :icon="getSectionType(sub.type).icon" />
                      {{ getSectionType(sub.type).title }}
                    </div>
                  </template>
                </td>
                <td class="px-6 py-4">
                  <router-link :to="`${this.trainingId}/${section.short_url}`"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <Icon class="w-5 h-5" icon="ph-pen" />
                    <span class="sr-only">Icon description</span>
                  </router-link>
                  <button type="button" @click="toggleEditModal(section)"
                    class="text-blue-700 border border-blue-700 hover:border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                    <Icon class="w-5 h-5" icon="ph-gear" />
                    <span class="sr-only">Icon description</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="sections.length > 0" class="pb-4 pl-4 sm:pb-6 sm: pl-6">
            <button @click="toggleAddModal"
              class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Lägg
              till</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modals -->
  <div id="add-section-modal" tabindex="-1" aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="p-4 w-full max-w-xl max-h-full">
      <div class="bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Ny sektion
          </h3>
          <button @click="toggleAddModal" type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white outline-none">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Stäng popup</span>
          </button>
        </div>
        <form @submit.prevent="createTrainingSection" class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2">
            <div class="col-span-2">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
              <input type="text" name="title" id="title" v-model="createSectionData.title"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Vad ska modulen heta?" required>
            </div>
            <div class="col-span-2">
              <label for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
              <textarea id="description" rows="4" v-model="createSectionData.description"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Beskriv modulen i korthet" required></textarea>
            </div>
            <div class="col-span-2">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Moduler</label>
              <draggable v-if="createSectionData.sub_sections.length != 0"
                class="flex flex-col gap-2 items-center justify-center w-full min-h-48 p-5 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                :list="createSectionData.sub_sections" group="sections" item-key="title" ghost-class="ghost"
                drag-class="opacity-0">
                <template #item="{ element, index }">
                  <div class="w-full">
                    <div class="flex text-center">
                      <div class="flex w-fit border m-auto p-5 justify-center">
                        <div v-if="getSectionType(element.type)" :class="`${getSectionType(element.type).color}`">
                          <Icon class="w-10 h-10" :icon="getSectionType(element.type).icon" />
                        </div>
                      </div>
                      <div class="w-fit m-auto">
                        <div class="w-full text-lg font-semibold text-gray-900 dark:text-white">{{ element.title }}
                        </div>
                      </div>
                      <div class="m-auto">
                        <button type="button" @click="deleteSubSection(index, true)"
                          class="text-blue-700 hover:border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                          <Icon class="w-5 h-5" icon="ph-x-bold" />
                          <span class="sr-only">Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
              <div v-if="createSectionData.sub_sections.length == 0" class="relative min-h-48">
                <draggable
                  class="absolute flex flex-col items-center justify-center w-full min-h-48 p-5 border-2 border-blue-300 border-dashed rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                  :list="createSectionData.sub_sections" group="sections" item-key="title">
                  <template #item="{ element, index }">

                  </template>
                </draggable>
                <div :class="{ 'opacity-20 pb-28': drag }"
                  class="absolute flex flex-col items-center justify-center w-full min-h-48 text-sm font-medium text-gray-900 dark:text-white">
                  Dra och släpp modulerna här..
                </div>
              </div>
              <draggable class="flex flex-row justify-center mt-5" :list="sectionTypes" :sort="false"
                :group="{ name: 'sections', pull: 'clone', put: false }" @start="drag = true" @end="drag = false"
                :clone="cloneSubSection" item-key="title">
                <template #item="{ element }">
                  <button type="button"
                    :class="`${element.bg_color} ${element.color} dark:${element.dark_bg} dark:${element.dark_color}`"
                    class="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">
                    {{ element.title }}
                  </button>
                </template>
              </draggable>
            </div>
            <div v-if="errorText"
              class="col-span-2 flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 20 20">
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                {{ errorText }}
              </div>
            </div>
          </div>
          <button type="submit"
            class="text-white inline-flex items-center bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
            <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:plus" />
            Skapa modul
          </button>
        </form>
      </div>
    </div>
  </div>
  <div id="edit-section-modal" tabindex="-1" aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="p-4 w-full max-w-xl max-h-full">
      <div class="bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Redigera sektion
          </h3>
          <button @click="toggleEditModal" type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white outline-none">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Stäng popup</span>
          </button>
        </div>
        <form @submit.prevent="editTrainingSection" class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2">
            <div class="col-span-2">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
              <input type="text" name="title" id="title" v-model="editedSectionData.title"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Vad ska modulen heta?" required>
            </div>
            <div class="col-span-2">
              <label for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
              <textarea id="description" rows="4" v-model="editedSectionData.description"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Beskriv modulen i korthet" required></textarea>
            </div>
            <div class="col-span-2">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Moduler</label>
              <draggable
                class="flex flex-col gap-2 items-center justify-center w-full min-h-48 p-5 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                :list="editedSectionData.sub_sections" group="edit_sections" item-key="title" ghost-class="ghost"
                drag-class="opacity-0">
                <template #item="{ element, index }">
                  <div class="w-full">
                    <div class="flex text-center dark:text-white">
                      <div class="flex w-fit border m-auto p-5 justify-center">
                        <div v-if="getSectionType(element.type)" :class="`${getSectionType(element.type).color}`">
                          <Icon class="w-10 h-10" :icon="getSectionType(element.type).icon" />
                        </div>
                      </div>
                      <div class="w-fit m-auto">
                        <div class="w-full text-lg font-semibold text-gray-900 dark:text-white">{{ element.title }}
                        </div>
                      </div>
                      <div class="m-auto">
                        <button type="button" @click="deleteSubSection(index, false)"
                          class="text-blue-700 hover:border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                          <Icon class="w-5 h-5" icon="ph-x-bold" />
                          <span class="sr-only">Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
              <draggable class="flex flex-row justify-center mt-5" :list="sectionTypes" :sort="false"
                :group="{ name: 'edit_sections', pull: 'clone', put: false }" :clone="editSubSection" item-key="title">
                <template #item="{ element }">
                  <button type="button"
                    :class="`${element.bg_color} ${element.color} dark:${element.dark_bg} dark:${element.dark_color}`"
                    class="focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">
                    {{ element.title }}
                  </button>
                </template>
              </draggable>
            </div>
            <div v-if="errorText"
              class="col-span-2 flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 20 20">
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                {{ errorText }}
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <button type="submit"
              class="text-white inline-flex items-center bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
              <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:plus" />
              Spara ändringar
            </button>
            <button @click="deleteTrainingSection(editedSectionData._id)"
              class="text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
              <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:minus-circle" />
              Ta bort
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AdminPexelsComponent from "@/views/admin/PexelsComponent.vue"
import { Icon } from "@iconify/vue";
import { getTraining, editTraining, fetchSectionsByTraining, createTrainingSection, editTrainingSection, deleteTrainingSection } from "@/api/index.js";
import { initFlowbite, Modal } from 'flowbite';
import draggable from 'vuedraggable'
import axios from "axios";

export default {
  emits: ['scrollToTop'],
  data() {
    return {
      trainingId: null,
      training: {},
      editedTraining: {
        _id: null,
        title: "",
        subtitle: "",
        description: "",
        type: "",
        image_url: "",
      },
      selectedType: '',
      add_course_modal: null,
      edit_course_modal: null,
      sectionTypes: [],
      createSectionData: {
        training: '',
        short_url: '',
        title: '',
        description: '',
        sub_sections: [],
      },
      editedSectionData: {
        _id: null,
        title: '',
        description: '',
        sub_sections: [],
      },
      sections: [],
      errorText: "",
      dropdownSection: null,
      drag: false,
    };
  },
  components: {
    Icon, draggable, AdminPexelsComponent
  },
  mounted() {
    initFlowbite();
    this.trainingId = this.$route.params.id;
    this.fetchTraining();
    this.fetchSectionTypes();
    const $addModal = document.getElementById('add-section-modal');
    const $editModal = document.getElementById('edit-section-modal');

    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-50',
      closable: true,
      onHide: () => { },
      onShow: () => { },
      onToggle: () => { },
    };

    const addInstanceOptions = {
      id: 'add-section-modal',
      override: true
    };
    const editInstanceOptions = {
      id: 'edit-section-modal',
      override: true
    };

    this.add_course_modal = new Modal($addModal, options, addInstanceOptions);
    this.edit_course_modal = new Modal($editModal, options, editInstanceOptions);
  },
  computed: {
    isEdited() {
      return (
        this.editedTraining.title !== this.training.title ||
        this.editedTraining.subtitle !== this.training.subtitle ||
        this.editedTraining.description !== this.training.description ||
        this.editedTraining.type !== this.training.type ||
        this.editedTraining.image_url !== this.training.image_url
      );
    }
  },
  methods: {
    // API Functions
    async fetchTraining() {
      try {
        const response = await getTraining(this.trainingId);
        this.training = response;
        this.fetchSections(response._id);
        this.resetEdit(response)
      } catch (error) {
        console.error('Error fetching training:', error);
        this.$router.push('/admin/utbildningar/');
      }
    },
    async fetchSectionTypes() {
      try {
        let apiUrl;
        if (window.location.protocol === 'https:') {
          apiUrl = "https://" + window.location.hostname + ":4000/api/sectiontypes";
        } else {
          apiUrl = "http://" + window.location.hostname + ":4000/api/sectiontypes";
        }

        const response = await axios.get(apiUrl);
        this.sectionTypes = response.data;
      } catch (error) {
        console.error('Error fetching section types:', error);
      }
    },
    async updateTraining() {
      try {
        await editTraining(this.editedTraining._id, this.editedTraining);
        this.fetchTraining();
      } catch (error) {
        console.error("Error updating training:", error);
      }
    },
    async fetchSections(id) {
      try {
        this.sections = await fetchSectionsByTraining(id);
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    },
    async createTrainingSection() {
      try {
        this.createSectionData.short_url = this.generateUniqueIdentifier(this.createSectionData.title);
        await createTrainingSection(this.createSectionData);
        this.resetForm();
        this.fetchSections(this.training._id);
        this.toggleAddModal();
      } catch (error) {
        console.error('Error creating section:', error);
      }
    },
    async editTrainingSection() {
      try {
        await editTrainingSection(this.editedSectionData._id, this.editedSectionData);
        this.resetForm();
        this.fetchSections(this.training._id);
        this.toggleEditModal();
      } catch (error) {
        console.error('Error creating section:', error);
      }
    },
    async deleteTrainingSection(id) {
      try {
        await deleteTrainingSection(id)
        this.fetchSections(this.training._id);
      } catch (error) {
        console.error("Error updating training:", error);
      }
    },

    // Functions
    toggleAddModal() {
      this.createSectionData.training = this.training._id;
      this.add_course_modal.toggle()
    },
    toggleEditModal(value) {
      if (value) {
        this.editedSectionData._id = value._id;
        this.editedSectionData.title = value.title;
        this.editedSectionData.description = value.description;
        this.editedSectionData.sub_sections = value.sub_sections;
        this.edit_course_modal.toggle()
      } else {
        this.edit_course_modal.toggle()
      }
    },
    resetEdit(value) {
      this.editedTraining._id = value._id;
      this.editedTraining.title = value.title;
      this.editedTraining.subtitle = value.subtitle;
      this.editedTraining.description = value.description;
      this.editedTraining.type = value.type;
      this.editedTraining.image_url = value.image_url;
    },
    resetForm() {
      this.createSectionData.training = '';
      this.createSectionData.short_url = '';
      this.createSectionData.title = '';
      this.createSectionData.description = '';
      this.createSectionData.sub_sections = [];
    },
    generateUniqueIdentifier(title) {
      const accentMap = {
        'á': 'a', 'à': 'a', 'â': 'a', 'ã': 'a', 'å': 'a', 'ā': 'a',
        'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e', 'ē': 'e', 'ė': 'e', 'ę': 'e',
        'í': 'i', 'ì': 'i', 'î': 'i', 'ï': 'i', 'ī': 'i',
        'ó': 'o', 'ò': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o', 'ō': 'o',
        'ú': 'u', 'ù': 'u', 'û': 'u', 'ü': 'u', 'ū': 'u',
        'ç': 'c',
        'ñ': 'n',
        'æ': 'ae',
        'ø': 'o'
      };

      const sanitizedTitle = title
        .toLowerCase()
        .replace(/ /g, "-") // Replace spaces with dashes
        .normalize("NFD") // Normalize accented characters
        .replace(/[^\u0000-\u007F]/g, function (character) {
          return accentMap[character] || ''; // Replace accented characters
        })
        .replace(/[^\w\s-]|_/g, "") // Remove special characters except spaces and dashes

      return sanitizedTitle;
    },
    cloneSubSection({ id, title }) {
      return {
        type: id,
        title: title
      };
    },
    editSubSection({ id, title }) {
      return {
        type: id,
        title: title,
        data: {
          layout: 'Layout 1',
        }
      };
    },
    deleteSubSection(idx, created) {
      created ? this.createSectionData.sub_sections.splice(idx, 1) : this.editedSectionData.sub_sections.splice(idx, 1);
    },
    getSectionType(type) {
      return this.sectionTypes.find(sectionType => sectionType.id === type);
    },
    setEdited(val) {
      this.isEdited = val;
    }
  }
};
</script>

<style scoped>
.ghost {
  opacity: .7;
  background-color: #e0e0e0;
  border-radius: 100px;
}
</style>