<template>
  <div class="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:border-gray-700">
    <div class="w-full mb-1">
      <div class="sm:flex">
        <div class="">
          <form class="flex items-center">
            <label for="simple-search" class="sr-only">Sök</label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                  viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd" />
                </svg>
              </div>
              <input v-model="searchQuery" type="text" id="simple-search"
                class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Sök">
            </div>
          </form>
        </div>
        <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
          <button type="button" @click="toggleTrainingModal"
            class="hidden sm:inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            <Icon class="w-4 h-4 mr-0 sm:mr-2" icon="ph-books" />
            <span class="hidden sm:block">Lägg till utbildning</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="relative overflow-x-auto shadow">
    <table class="w-full divide-y divide-gray-200 dark:divide-gray-600">
      <thead class="bg-gray-100 dark:bg-gray-700">
        <tr>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Titel
          </th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Obligatorisk
          </th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Enbart godkännande
          </th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Antal genomförda
          </th>
          <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
        <tr v-for="training in filteredTrainings" :key="training._id" class="hover:bg-gray-100 dark:hover:bg-gray-950">
          <td class="flex items-center p-4 mr-12 space-x-6">
            <img class="w-10 h-10 rounded-full"
              :src="[training.image_url ? training.image_url : 'https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2']"
              alt="avatar">
            <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
              <div class="text-base font-semibold text-gray-900 dark:text-white">{{ training.title }}</div>
            </div>
          </td>
          <td class="p-4 text-base font-medium text-gray-900 dark:text-white">{{ training.mandatory ? 'Ja' : '' }}
          </td>
          <td class="p-4 text-base font-medium text-gray-900 dark:text-white">{{ training.accept_only ? 'Ja' : '' }}
          </td>
          <td class="p-4 text-base font-medium text-gray-900 dark:text-white"></td>
          <td class="p-4 space-x-2">
            <router-link :to="`/admin/utbildningar/${training.short_url}`"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <Icon class="w-5 h-5" icon="ph-pen" />
              <span class="sr-only">Icon description</span>
            </router-link>
            <button type="button" @click="editTraining(training)"
              class="text-blue-700 border border-blue-700 hover:border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
              <Icon class="w-5 h-5" icon="ph-gear" />
              <span class="sr-only">Icon description</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Modals -->
  <div id="add-training-modal" tabindex="-1" aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Ny utbildning
          </h3>
          <button @click="toggleTrainingModal" type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white outline-none">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <form @submit.prevent="createTraining" class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2">
            <div class="col-span-2">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
              <input type="text" name="title" id="title" v-model="title"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Vad ska utbildningen heta?" required>
            </div>
            <div class="col-span-2">
              <label for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
              <textarea id="description" rows="6" v-model="description"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Beskriv utbildningen i korthet" required></textarea>
            </div>
            <div v-if="errorText"
              class="col-span-2 flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 20 20">
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                {{ errorText }}
              </div>
            </div>
          </div>
          <button type="submit"
            class="text-white inline-flex items-center bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
            <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:plus" />
            Skapa utbildningen
          </button>
        </form>
      </div>
    </div>
  </div>

  <div id="edit-training-modal" tabindex="-1" aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Ändra utbildning
          </h3>
          <button @click="toggleEditModal" type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white outline-none">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <form @submit.prevent="updateTraining" class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2">
            <div class="col-span-2">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titel</label>
              <input type="text" name="title" id="title" v-model="editedTraining.title"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Vad ska utbildningen heta?" required>
            </div>
            <div class="col-span-2">
              <label for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
              <textarea id="description" rows="6" v-model="editedTraining.description"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Beskriv utbildningen i korthet" required></textarea>
            </div>
            <div class="col-span-2 flex items-center">
              <input id="mandatory-checkbox" type="checkbox" value="" v-model="editedTraining.mandatory"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="mandatory-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Är
                utbildningen obligatorisk?</label>
            </div>
            <div class="col-span-2 flex items-center mb-4">
              <input id="acceptonly-checkbox" type="checkbox" value="" v-model="editedTraining.accept_only"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="acceptonly-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Ska
                användaren bara godkänna?</label>
            </div>
            <div v-if="!editedTraining.accept_only" class="col-span-2 flex flex-col">
              <p class="block mb-5 text-sm font-medium text-gray-900 dark:text-white">Tröskel för att bli godkänd</p>
              <div class="flex flex-col items-center">
                <input id="steps-range" type="range" min="0" max="100" step="10"
                  v-model="editedTraining.approved_threshold"
                  class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-600">
                <label for="steps-range" class="block mt-2 text-sm font-medium text-gray-900 dark:text-white">{{
                editedTraining.approved_threshold }}%</label>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <button type="submit"
              class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <Icon class="w-4 h-4 mr-2" icon="ph:pen" />
              Spara
            </button>
            <button @click="deleteTraining(editedTraining._id)"
              class="text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
              <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:minus-circle" />
              Ta bort
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { createTraining, getAllTrainings, editTraining, deleteTraining } from "@/api/index.js";
import { initFlowbite, Modal } from 'flowbite';
import { Icon } from "@iconify/vue";

export default {
  emits: ['scrollToTop'],
  data() {
    return {
      title: "",
      description: "",
      trainings: [],
      add_training_modal: null,
      edit_training_modal: null,
      searchQuery: "",
      editedTraining: {
        _id: null,
        title: "",
        description: "",
        mandatory: false,
        accept_only: false,
        approved_threshold: 100,
      },
      errorText: ""
    };
  },
  components: {
    Icon,
  },
  methods: {
    toggleTrainingModal() {
      this.add_training_modal.toggle();
    },
    toggleEditModal() {
      this.edit_training_modal.toggle();
    },
    async fetchAllTrainings() {
      try {
        this.trainings = await getAllTrainings();
      } catch (error) {
        console.error("Error fetching trainings:", error);
      }
    },
    async createTraining() {
      try {
        const shortUrl = this.generateUniqueIdentifier(this.title);
        await createTraining(this.title, this.description, shortUrl);
        this.title = ""
        this.description = "";
        this.errorText = "";
        this.fetchAllTrainings();
        this.toggleTrainingModal();
      } catch (error) {
        this.errorText = error.response.data.message;
        console.error("Error creating training:", error);
      }
    },
    async editTraining(training) {
      this.editedTraining._id = training._id;
      this.editedTraining.title = training.title;
      this.editedTraining.description = training.description;
      this.editedTraining.mandatory = training.mandatory;
      this.editedTraining.accept_only = training.accept_only;
      this.editedTraining.approved_threshold = training.approved_threshold;
      this.toggleEditModal();
    },
    async updateTraining() {
      try {
        await editTraining(this.editedTraining._id, this.editedTraining);
        this.trainings = await getAllTrainings();
        this.toggleEditModal();
      } catch (error) {
        console.error("Error updating training:", error);
      }
    },
    async deleteTraining(trainingId) {
      try {
        await deleteTraining(trainingId);
        this.trainings = await getAllTrainings();
      } catch (error) {
        console.error("Error deleting training:", error);
      }
    },
    generateUniqueIdentifier(title) {
      const accentMap = {
        'á': 'a', 'à': 'a', 'â': 'a', 'ã': 'a', 'å': 'a', 'ā': 'a',
        'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e', 'ē': 'e', 'ė': 'e', 'ę': 'e',
        'í': 'i', 'ì': 'i', 'î': 'i', 'ï': 'i', 'ī': 'i',
        'ó': 'o', 'ò': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o', 'ō': 'o',
        'ú': 'u', 'ù': 'u', 'û': 'u', 'ü': 'u', 'ū': 'u',
        'ç': 'c',
        'ñ': 'n',
        'æ': 'ae',
        'ø': 'o'
      };

      const sanitizedTitle = title
        .toLowerCase()
        .replace(/ /g, "-") // Replace spaces with dashes
        .normalize("NFD") // Normalize accented characters
        .replace(/[^\u0000-\u007F]/g, function (character) {
          return accentMap[character] || ''; // Replace accented characters
        })
        // .replace(/[\u0300-\u036f]/g, "") // Remove combining diacritical marks
        .replace(/[^\w\s-]|_/g, "") // Remove special characters except spaces and dashes

      return sanitizedTitle;
    }
  },
  mounted() {
    initFlowbite();
    const addModal = document.getElementById('add-training-modal');
    const editModal = document.getElementById('edit-training-modal');

    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-50',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    const addInstanceOptions = {
      id: 'add-training-modal',
      override: true
    };

    const editInstanceOptions = {
      id: 'edit-training-modal',
      override: true
    };

    this.add_training_modal = new Modal(addModal, options, addInstanceOptions);
    this.edit_training_modal = new Modal(editModal, options, editInstanceOptions);
    this.fetchAllTrainings();
  },
  computed: {
    filteredTrainings() {
      const query = this.searchQuery.toLowerCase();
      return this.trainings.filter(training => {
        return (
          training.title.toLowerCase().includes(query) ||
          training.description.toLowerCase().includes(query)
        );
      });
    }
  }
};
</script>
