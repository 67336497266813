import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "flowbite";
import "./assets/tailwind.css";
import "./assets/custom.css";
import axios from "axios";
import Emitter from "tiny-emitter";
import VueApexCharts from "vue3-apexcharts";

const token = localStorage.getItem("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const app = createApp(App);
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.use(createPinia());
app.use(VueApexCharts);
app.use(router);
app.mount("#app");
