<template>
  <section class="p-4">
    <div class="grid grid-cols-2 lg:grid-cols-3 gap-4 py-8 px-4 mx-auto max-w-screen-xl">
      <div
        class="flex flex-col items-center justify-center bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg h-64">
        <Icon class="w-16 h-16 lg:w-20 lg:h-20 text-gray-900 dark:text-gray-200" icon="ph-student" />
        <p class="text-sm text-gray-500 dark:text-gray-400 mt-5 mb-2">Gjorda utbildningar</p>
        <h2 class="text-gray-900 dark:text-white text-6xl lg:text-7xl font-extrabold">
          <span ref="trainingsCounter">0</span>
          <span class="text-lg lg:text-2xl ml-2">/ {{ countTrainings }}</span>
        </h2>
      </div>
      <div
        class="flex flex-col items-center justify-center bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg h-64">
        <Icon class="w-20 h-20 text-gray-900 dark:text-gray-200" icon="ph-file-doc" />
        <p class="text-sm text-gray-500 dark:text-gray-400 mt-5 mb-2">Godkända policys</p>
        <h2 class="text-gray-900 dark:text-white text-7xl font-extrabold">
          <span ref="policiesCounter">0</span>
          <span class="text-2xl ml-2">/ {{ countPolicies }}</span>
        </h2>
      </div>
      <!-- <div class="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg">
      </div> -->
    </div>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
      <h1 class="text-gray-900 dark:text-white text-3xl md:text-5xl font-extrabold mb-12">
        Välkommen {{ user.fullname }}
      </h1>
      <div class="grid md:grid-cols-2 gap-8">
        <div v-for="training in mandatoryTrainings"
          class="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
          <div class="flex justify-between mb-5">
            <div v-if="isTrainingCompleted(training)"
              class="inline-flex justify-between items-center py-1 px-1 pr-0 lg:pr-4 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-white"
              role="alert">
              <span v-if="isTrainingApproved(training)"
                class="text-xs bg-green-600 rounded-full text-white px-4 py-1.5 mr-1 lg:mr-3">
                {{ training.accept_only ? 'Godkänt' : 'Godkänd' }}
              </span>
              <span v-else class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3">Ej godkänd</span>
              <span class="hidden lg:block text-sm font-medium">{{ getAcceptedDate(training) }}</span>
            </div>
            <span
              class="inline-flex items-center h-8 bg-yellow-100 text-yellow-800 text-xs font-medium px-3 py-2 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
              <Icon class="w-4 h-4 mr-2" icon="ph-alarm" />
              Obligatorisk
            </span>
          </div>
          <h2 class="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">{{ training.title }}</h2>
          <p class="text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">{{ training.description }}</p>
          <router-link :to="{
            name: 'Training', params: {
              id: training.short_url
            }
          }" class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white
            rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300
            dark:focus:ring-primary-900">
            {{ training.accept_only ? 'Gå till policyn' : 'Gå till utbildningen' }}
            <Icon class="w-5 h-5 ml-2" icon="ph-arrow-right" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <div
    class="hidden bg-green-100 dark:text-green-400 text-green-800 bg-yellow-100 dark:text-yellow-400 text-yellow-800 bg-purple-100 dark:text-purple-400 text-purple-800 bg-blue-100 dark:text-blue-400 text-blue-800 dark:bg-gray-700">
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { fetchUserResponsesForUser } from '@/api/index.js';
import { useUserStore } from '@/store/user.js';
import { initFlowbite } from 'flowbite'
import { useTrainingsStore } from '@/store/trainings.js';

import { reactive, watch } from 'vue'
import gsap from 'gsap'

export default {
  emits: ['scrollToTop'],
  data() {
    return {
      allUserStats: [],
      trainingsCompleted: 0,
      policiesAccepted: 0,
      tweened1: reactive({ number: 0 }),
      tweened2: reactive({ number: 0 }),
    }
  },
  components: {
    Icon,
  },
  computed: {
    user() {
      return useUserStore().user;
    },
    countTrainings() {
      return this.allTrainings.length
    },
    countPolicies() {
      return this.allPolicies.length
    },
    allTrainings() {
      const allTrainings = useTrainingsStore().allTrainings;
      if (!allTrainings || typeof allTrainings !== 'object') {
        return [];
      }
      const trainings = Object.values(allTrainings).filter(training => !training.accept_only);
      return trainings;
    },
    allPolicies() {
      const allTrainings = useTrainingsStore().allTrainings;
      if (!allTrainings || typeof allTrainings !== 'object') {
        return [];
      }
      const trainings = Object.values(allTrainings).filter(training => training.accept_only);
      return trainings;
    },
    mandatoryTrainings() {
      const trainings = useTrainingsStore().allTrainings;
      if (!trainings || typeof trainings !== 'object') {
        return [];
      }
      const mandatoryTrainings = Object.values(trainings).filter(training => training.mandatory);
      return mandatoryTrainings;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue._id) {
          this.getAllStats();
        }
      }
    }
  },
  methods: {
    async getAllStats() {
      try {
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
        this.checkTrainingsAndPoliciesCompleted();
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    isTrainingCompleted(training) {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === training._id);
      return matchingEntries.some(entry => {
        if (entry.responses.length !== 0) {
          return true;
        } else {
          return entry.accepted;
        }
      });
    },
    isTrainingApproved(training) {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === training._id);
      return matchingEntries.some(entry => {
        if (entry.responses.length !== 0) {
          let maxCorrect = 0;
          let totalQuestions = 0;
          entry.responses.forEach(response => {
            totalQuestions++;
            if (response.correct[0]) {
              maxCorrect++;
            }
          });
          const bestPercentage = totalQuestions === 0 ? 0 : (maxCorrect / totalQuestions) * 100;
          return bestPercentage >= training.approved_threshold;
        } else {
          return entry.accepted;
        }
      });
    },
    getAcceptedDate(training) {
      const matchingEntries = this.allUserStats.filter(
        entry => entry.training_section === training._id
      );
      let latestTimestamp = null;
      matchingEntries.forEach(entry => {
        const entryTimestamp = new Date(entry.timestamp);
        if (!latestTimestamp || entryTimestamp > latestTimestamp) {
          latestTimestamp = entryTimestamp;
        }
      });
      if (latestTimestamp) {
        const date = new Date(latestTimestamp);
        const formattedDay = date.getDate();
        const formattedMonth = new Intl.DateTimeFormat('sv-SE', { month: 'long' }).format(date);
        const currentYear = new Date().getFullYear();
        const formattedYear = date.getFullYear() === currentYear ? '' : ` ${date.getFullYear()}`;
        return `${formattedDay} ${formattedMonth}${formattedYear}`;
      }
      return '';
    },
    checkTrainingsAndPoliciesCompleted() {
      const uniqueTrainings = new Set(
        this.allUserStats
          .filter(entry => entry.responses.length > 0)
          .map(entry => entry.training_section)
      );
      this.trainingsCompleted = uniqueTrainings.size;
      const uniquePolicies = new Set(
        this.allUserStats
          .filter(entry => entry.accepted)
          .map(entry => entry.training_section)
      );
      this.trainingsCompleted = uniqueTrainings.size;
      this.policiesAccepted = uniquePolicies.size;
      this.setupAnimation();
    },
    setupAnimation() {
      const trainingsCounter = this.$refs.trainingsCounter;
      const policiesCounter = this.$refs.policiesCounter;
      gsap.to(this.tweened1, { duration: 1.2, number: this.trainingsCompleted });
      gsap.to(this.tweened2, { duration: 1.2, number: this.policiesAccepted });

      watch(() => this.tweened1.number, () => {
        trainingsCounter.textContent = this.tweened1.number.toFixed(0);
      });
      watch(() => this.tweened2.number, () => {
        policiesCounter.textContent = this.tweened2.number.toFixed(0);
      });
    },
  },
  mounted() {
    initFlowbite();
  },
};
</script>
