<template>
  <section v-if="trainingData" class="overflow-x-hidden h-full dark:bg-gray-900">
    <div class="max-w-screen-xl px-0 py-0 mx-auto lg:gap-8 xl:gap-0 lg:py-12 grid-cols-1 lg:grid-cols-12">
      <div class="px-4 mr-auto">
        <Tilt class="drop-shadow-3xl hover:drop-shadow-4xl" :options="tiltOpt">
          <Certificate :trainingData="trainingData" />
          <!-- <div
            class="diplom p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology
                acquisitions 2021</h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology
              acquisitions of 2021 so far, in reverse chronological order.</p>
            <a href="#"
              class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Read more
              <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
          </div> -->
        </Tilt>
      </div>
    </div>
  </section>
</template>

<script>
// import { Icon } from "@iconify/vue";
import { getTraining, fetchSectionsByTraining, fetchUserResponsesForUser } from "@/api/index.js";
import { useUserReponse } from '@/store/responses.js';
import { useUserStore } from "@/store/user";
import Tilt from 'vanilla-tilt-vue';
import Certificate from '@/components/CertificateComponent.vue'

export default {
  props: {
    training: String,
  },
  data() {
    return {
      training_shorturl: this.$route.params.training,
      trainingData: {},
      sections: [],
      numberOfAllQuestions: 0,
      allUserStats: [],
      listQuestions: [],
      howManyTrainingsDone: 0,
      trainingIsCompleted: false,
      trainingIsApproved: false,
      bestPercentage: 0,
      acceptedYear: "",
      tiltOpt: {
        reverse: true, // reverse the tilt direction
        max: 5, // max tilt rotation (degrees)
        startX: 0, // the starting tilt on the X axis, in degrees.
        startY: 0, // the starting tilt on the Y axis, in degrees.
        perspective: 1500, // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1, // 2 = 200%, 1.5 = 150%, etc..
        speed: 1000, // Speed of the enter/exit transition
        transition: true, // Set a transition on enter/exit.
        reset: true, // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        glare: true, // if it should have a "glare" effect
        "max-glare": 0.8, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false, // false = VanillaTilt creates the glare elements for you, otherwise
        "mouse-event-element": null, // css-selector or link to HTML-element what will be listen mouse events
        gyroscope: true, // Boolean to enable/disable device orientation detection,
        gyroscopeMinAngleX: -45, // This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element;
        gyroscopeMaxAngleX: 45, // This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element;
        gyroscopeMinAngleY: -45, // This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element;
        gyroscopeMaxAngleY: 45, // This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element;
      }
    };
  },
  components: {
    Tilt,
    Certificate
    // Icon,
  },
  mounted() {
    this.fetchTraining();
  },
  computed: {
    // firstSection() {
    //   return Object(this.sections[0]).short_url
    // },
    statsForThisTraining() {
      const statsForThisTraining = this.allUserStats.filter(stat => stat.training_section === this.trainingData._id)
      return statsForThisTraining
    },
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    amountOfTotalQuestions() {
      return useUserReponse().activeTraining.amountOfQuestions;
    },
    user() {
      return useUserStore().user;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue._id) {
          if (useUserReponse().userResponse.response.length === 0) {
            this.getAllStats();
          }
        }
      }
    }
  },
  methods: {
    async fetchTraining() {
      try {
        const response = await getTraining(this.training_shorturl);
        this.trainingData = response;
        this.fetchCourses(response._id);
      } catch (error) {
        console.error('Error fetching training:', error);
      }
    },
    async fetchCourses(id) {
      try {
        this.sections = await fetchSectionsByTraining(id);
        this.totalQuestions();
        this.getAllStats();
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    },
    async getAllStats() {
      try {
        this.howManyTrainingsDone = 0
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
        this.allUserStats.forEach((stat) => {
          if (stat.training_section === this.trainingData._id) {
            this.howManyTrainingsDone++
          }
        })
        this.isTrainingCompleted(this.trainingData);
        this.isTrainingApproved(this.trainingData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    totalQuestions() {
      this.numberOfAllQuestions = 0
      this.sections.forEach(s => {
        s.sub_sections.forEach(ss => {
          if (ss.type === 'questions') {
            ss.data.questions.forEach(question => {
              this.listQuestions.push(question);
              this.numberOfAllQuestions++;
            });
          }
        })
      });
    },
    isTrainingCompleted(training) {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === training._id);
      this.trainingIsCompleted = false;
      matchingEntries.some(entry => {
        if (entry.responses.length !== 0) {
          this.trainingIsCompleted = true;
        }
      });
    },
    isTrainingApproved(training) {
      const matchingEntries = this.allUserStats.filter(entry => entry.training_section === training._id);
      this.trainingIsApproved = false;
      return matchingEntries.some(entry => {
        if (entry.responses.length !== 0) {
          let maxCorrect = 0;
          let totalQuestions = 0;
          entry.responses.forEach(response => {
            totalQuestions++;
            if (response.correct[0]) {
              maxCorrect++;
            }
          });
          const bestPercentage = totalQuestions === 0 ? 0 : (maxCorrect / totalQuestions) * 100;
          this.bestPercentage = bestPercentage;
          return this.trainingIsApproved = bestPercentage >= training.approved_threshold;
        }
      });
    },
    getAcceptedDate(training) {
      const matchingEntries = this.allUserStats.filter(
        entry => entry.training_section === training._id
      );
      let latestTimestamp = null;
      matchingEntries.forEach(entry => {
        const entryTimestamp = new Date(entry.timestamp);
        if (!latestTimestamp || entryTimestamp > latestTimestamp) {
          latestTimestamp = entryTimestamp;
        }
      });
      if (latestTimestamp) {
        const date = new Date(latestTimestamp);
        const formattedDay = date.getDate();
        const formattedMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
        const currentYear = new Date().getFullYear();
        const formattedYear = date.getFullYear() === currentYear ? '' : ` ${date.getFullYear()}`;
        return `${formattedDay} ${formattedMonth}${formattedYear}`;
      }
      return '';
    },
  }
};
</script>