<template>
  <div class="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:border-gray-700">
    <div class="w-full mb-1">
      <div class="sm:flex">
        <div class="flex items-center mr-auto space-x-2">
          <form class="flex items-center">
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                  viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd" />
                </svg>
              </div>
              <input v-model="searchQuery" type="text" id="simple-search"
                class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Sök">
            </div>
          </form>
          <button v-if="searchQuery" @click="searchQuery = ''" type="button"
            class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg px-3 py-2 text-xs h-10 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
            Rensa sök
          </button>
        </div>
        <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
          <button id="dropdownSmallButton" data-dropdown-toggle="dropdownSmall" data-dropdown-placement="left-end"
            type="button"
            class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
            <Icon class="w-5 h-5 mr-2" icon="ph-funnel-simple" />
            Filter
          </button>
          <div id="dropdownSmall"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-80 dark:bg-gray-700 dark:divide-gray-600">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSmallButton">
              <li>
                <div class="flex p-2 pl-3 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <div class="flex items-center h-5">
                    <input id="showCompleted" v-model="filterCompleted" aria-describedby="showCompleted" type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  </div>
                  <div class="ms-2 text-sm">
                    <label for="showCompleted" class="font-medium text-gray-900 dark:text-gray-300">
                      <div>Visa utförda utbildningar</div>
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex p-2 pl-3 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <div class="flex items-center h-5">
                    <input id="showAccepted" v-model="filterAccepted" aria-describedby="showAccepted" type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  </div>
                  <div class="ms-2 text-sm">
                    <label for="showAccepted" class="font-medium text-gray-900 dark:text-gray-300">
                      <div>Visa godkända</div>
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex p-2 pl-3 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <div class="flex items-center h-5">
                    <input id="showUncompleted" v-model="filterUncompleted" aria-describedby="showUncompleted"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  </div>
                  <div class="ms-2 text-sm">
                    <label for="showUncompleted" class="font-medium text-gray-900 dark:text-gray-300">
                      <div>Visa ej utförda utbildningar</div>
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex p-2 pl-3 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <div class="flex items-center h-5">
                    <input id="showNotAccepted" v-model="filterNotAccepted" aria-describedby="showNotAccepted"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  </div>
                  <div class="ms-2 text-sm">
                    <label for="showNotAccepted" class="font-medium text-gray-900 dark:text-gray-300">
                      <div>Visa ej godkända</div>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
            <div class="py-2">
              <a href="#" @click="resetFilters"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Rensa
                alla filter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative overflow-x-auto shadow">
    <table class="w-full divide-y divide-gray-200 dark:divide-gray-600">
      <thead class="bg-gray-100 dark:bg-gray-700">
        <tr>
          <th scope="col"
            class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400 hover:cursor-pointer group/item"
            @click="sort('fullname')">
            <div class="flex">
              Namn
              <span v-if="sortColumn === 'fullname'" class="ml-1">
                <Icon v-if="sortDirection === 'asc'" class="w-3 h-3" icon="ph-caret-down-fill" />
                <Icon v-else class="w-3 h-3" icon="ph-caret-up-fill" />
              </span>
            </div>
          </th>
          <th v-for="training in trainings" :key="training._id" scope="col" @click="sort(training._id)"
            class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400 hover:cursor-pointer group/item">
            <div class="flex">
              {{ training.title }}
              <span v-if="sortColumn === training._id" class="ml-1">
                <Icon v-if="sortDirection === 'asc'" class="w-3 h-3" icon="ph-caret-down-fill" />
                <Icon v-else class="w-3 h-3" icon="ph-caret-up-fill" />
              </span>
              <span v-else class="invisible group-hover/item:visible ml-1">
                <Icon class="w-3 h-3 opacity-40" icon="ph-caret-down-fill" />
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
        <tr v-for="user in sortedUsers" :key="user._id" class="hover:bg-gray-100 dark:hover:bg-gray-950">
          <td class="flex items-center p-4 mr-12 space-x-6">
            <img class="w-10 h-10 rounded-full" :src="getUserImage(user)" alt="avatar">
            <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
              <div class="text-base font-semibold text-gray-900 dark:text-white">{{ user.fullname }}</div>
            </div>
          </td>
          <td v-for="training in trainings" :key="training._id"
            class="min-w-48 p-4 text-base font-medium text-gray-900 dark:text-white">
            <dl v-if="user.bestPercentage[training._id]">
              <dd class="flex items-center">
                <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                  <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    :style="{ width: user.bestPercentage[training._id] + '%' }">{{ user.bestPercentage[training._id] }}%
                  </div>
                </div>
              </dd>
            </dl>
            <dl v-if="user.accepted[training._id]">
              <dd class="flex items-center">
                <div class="">
                  <span
                    class="flex bg-green-100 text-green-800 text-sm font-medium me-2 px-3 py-1 rounded-full dark:bg-green-900 dark:text-green-300">
                    <Icon class="w-5 h-5 mr-2" icon="ph-check-circle" />
                    Godkänt
                  </span>
                </div>
              </dd>
            </dl>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="font-semibold text-gray-900 dark:text-white">
          <td class="px-6 py-3">Antal användare: {{ sortedUsers.length }}</td>
          <td v-for="training in trainings" :key="training._id" class="px-6 py-3">
            {{ getCompletedUsersCount(training._id) }} av {{ sortedUsers.length }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { getAllUsers, getAllTrainings, fetchAllStats } from '@/api/index.js';
import { initFlowbite } from 'flowbite'
import { Icon } from "@iconify/vue";
import defaultImage from "@/assets/img/default.png"

export default {
  emits: ['scrollToTop'],
  data() {
    return {
      users: [],
      searchQuery: "",
      trainings: [],
      allStats: [],
      sortColumn: 'fullname',
      sortDirection: 'asc',
      filterUncompleted: false,
      filterNotAccepted: false,
      filterCompleted: false,
      filterAccepted: false,
    }
  },
  components: {
    Icon,
  },
  methods: {
    async getAllUsers() {
      try {
        const users = await getAllUsers();
        this.users = users;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async fetchAllTrainings() {
      try {
        this.trainings = await getAllTrainings();
      } catch (error) {
        console.error("Error fetching trainings:", error);
      }
    },
    async fetchAllStats() {
      try {
        this.allStats = await fetchAllStats();
      } catch (error) {
        console.log("Error fetching stats;", error)
      }
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },
    getCompletedUsersCount(trainingId) {
      if (!trainingId) return 0;
      return this.sortedUsers.filter(user => user.bestPercentage[trainingId] || user.accepted[trainingId]).length;
    },
    resetFilters() {
      this.filterUncompleted = false;
      this.filterNotAccepted = false;
    },
    getUserImage(user) {
      const ad = user.azure_ad;
      if (ad && ad.profile_image !== undefined) {
        try {
          const bufferData = ad.profile_image.data;
          if (!bufferData || !Array.isArray(bufferData)) {
            console.error("Invalid profile image data");
            return;
          }
          const uint8Array = new Uint8Array(bufferData);
          const base64String = btoa(
            String.fromCharCode.apply(null, uint8Array)
          );
          const profileImageUrl = `data:image/jpeg;base64,${base64String}`;
          return profileImageUrl;
        } catch (error) {
          console.error("Error decoding profile image:", error);
        }
      } else {
        return defaultImage
      }
    },
  },
  mounted() {
    initFlowbite();
    this.getAllUsers();
    this.fetchAllTrainings();
    this.fetchAllStats();
  },
  computed: {
    sortedUsers() {
      return this.filteredUsers.sort((a, b) => {
        if (this.sortColumn === 'fullname') {
          return this.sortDirection === 'asc' ? a.fullname.localeCompare(b.fullname) : b.fullname.localeCompare(a.fullname);
        } else {
          const percentageA = a.bestPercentage[this.sortColumn] || 0;
          const percentageB = b.bestPercentage[this.sortColumn] || 0;
          // Check if the training has acceptance criteria
          const hasAcceptance = a.accepted[this.sortColumn] || b.accepted[this.sortColumn];
          if (hasAcceptance) {
            // If acceptance status is different, sort based on acceptance
            if (a.accepted[this.sortColumn] !== b.accepted[this.sortColumn]) {
              return this.sortDirection === 'asc' ?
                (a.accepted[this.sortColumn] ? -1 : 1) :
                (a.accepted[this.sortColumn] ? 1 : -1);
            }
          }
          // If both users have the same acceptance status, sort based on percentage
          return this.sortDirection === 'asc' ? percentageB - percentageA : percentageA - percentageB;
        }
      });
    },
    filteredUsers() {
      const query = this.searchQuery.toLowerCase();
      return this.users.map(user => {
        const stats = this.allStats.filter(stat => stat.user === user._id);
        const userBestPercentages = {};
        const acceptedResponse = {};

        // Iterate over all trainings to ensure all are accounted for
        this.trainings.forEach(training => {
          const trainingStats = stats.filter(stat => stat.training_section === training._id);
          const trainingCompleted = trainingStats.some(entry => entry.responses.length !== 0 || entry.accepted);

          if (trainingCompleted) {
            let maxCorrectPercentage = 0;
            trainingStats.forEach(entry => {
              const totalQuestions = entry.responses.length;
              const correctResponses = entry.responses.filter(response => response.correct[0]).length;
              const correctPercentage = totalQuestions === 0 ? 0 : (correctResponses / totalQuestions) * 100;
              maxCorrectPercentage = Math.max(maxCorrectPercentage, correctPercentage);
            });
            userBestPercentages[training._id] = Math.round(maxCorrectPercentage * 100) / 100;
          } else {
            // If training is not completed, set its percentage to undefined
            userBestPercentages[training._id] = undefined;
          }

          // Check if training is accepted and has not been previously marked as false
          const trainingAccepted = trainingStats.some(entry => entry.accepted);
          if (trainingAccepted) {
            acceptedResponse[training._id] = trainingAccepted;
          }
        });

        // Check if user has completed any trainings
        const hasCompletedAnyTraining = Object.values(userBestPercentages).some(percentage => percentage !== undefined);

        // Check if user has accepted any trainings
        const hasAcceptedAnyTraining = Object.values(acceptedResponse).some(accepted => accepted);

        // Check if user hasn't completed any trainings
        const hasntCompletedAnyTraining = Object.values(userBestPercentages).some(percentage => percentage === undefined);

        // Check if user hasn't accepted any trainings
        const hasntAcceptedAnyTraining = Object.values(acceptedResponse).every(accepted => !accepted);

        return {
          ...user,
          bestPercentage: userBestPercentages,
          accepted: acceptedResponse,
          hasCompletedAnyTraining,
          hasAcceptedAnyTraining,
          hasntCompletedAnyTraining,
          hasntAcceptedAnyTraining
        };
      }).filter(user => {
        return (
          (user.fullname.toLowerCase().includes(query) ||
            user.email.toLowerCase().includes(query)) &&
          (!this.filterUncompleted || user.hasntCompletedAnyTraining) &&
          (!this.filterNotAccepted || user.hasntAcceptedAnyTraining) &&
          (!this.filterCompleted || user.hasCompletedAnyTraining) &&
          (!this.filterAccepted || user.hasAcceptedAnyTraining)
        );
      });
    },
  }
};
</script>