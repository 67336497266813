<template>
  <div>
    <section v-for="(subSection, index) in sectionData.sub_sections" :key="index" class="pb-0 md:pt-32 last:pb-28">
      <template v-if="subSection.type === 'video'">
        <VideoSection :sectionId="sectionData._id" :subSectionId="subSection._id" :videoData="subSection.data"
          :isEditable="isEditable" />
      </template>
      <template v-else-if="subSection.type === 'image'">
        <ImageSection :sectionId="sectionData._id" :subSectionId="subSection._id" :imageData="subSection.data"
          :isEditable="isEditable" />
      </template>
      <template v-else-if="subSection.type === 'texts'">
        <TextsSection :sectionId="sectionData._id" :subSectionId="subSection._id" :textData="subSection.data"
          :isEditable="isEditable" />
      </template>
      <template v-else-if="subSection.type === 'questions'">
        <QuestionsSection :sectionId="sectionData._id" :trainingId="sectionData.training" :subSectionId="subSection._id"
          :questionData="subSection.data" :isEditable="isEditable" @send-answers="getAnswered" />
      </template>
      <template v-else-if="subSection.type === 'pdf'">
        <PDFSection :sectionId="sectionData._id" :trainingId="sectionData.training" :subSectionId="subSection._id"
          :pdfData="subSection.data" :sectionTitle="sectionData.title" :isEditable="isEditable" />
      </template>
    </section>
    <section v-if="acceptOnly">
      <AcceptSection :trainingId="sectionData.training" />
    </section>
  </div>
</template>

<script>
import VideoSection from "./VideoSection";
import ImageSection from './ImageSection.vue';
import TextsSection from './TextsSection.vue';
import QuestionsSection from './QuestionsSection.vue';
import PDFSection from './PDFSection.vue';
import AcceptSection from './AcceptSection.vue';
import { useUserReponse } from '@/store/responses.js';

export default {
  emits: ["sendDone"],
  props: {
    sectionData: Object,
    isEditable: Boolean,
    acceptOnly: Boolean,
    amountOfQuestions: Number,
  },
  components: {
    VideoSection,
    ImageSection,
    TextsSection,
    QuestionsSection,
    PDFSection,
    AcceptSection,
  },
  data() {
    return {
      answers: [],
    }
  },
  computed: {
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    allQuestionsAnswered() {
      if (this.answers.length == this.amountOfQuestions) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    allQuestionsAnswered: {
      handler: 'isDone',
      immediate: true,
    },
    // collectedResponse: {
    //   immediate: true,
    //   handler(value) {
    //     if (value.length !== 0) {
    //       console.log(value[0].sectionId, this.sectionData._id)
    //       if (value[0].sectionId !== this.sectionData._id) {
    //         useUserReponse().userResponse.response = []
    //       }
    //     }
    //   }
    // },
    sectionData: {
      immediate: true,
      handler() {
        let val = useUserReponse().userResponse.response
        if (val.length !== 0) {
          setTimeout(() => {
            if (val[0].trainingId !== this.sectionData.training) {
              useUserReponse().userResponse.response = []
            }
          }, 500);
        }
      }
    }
  },
  methods: {
    getAnswered(newData, oldData) {
      if (oldData) {
        const index = this.answers.findIndex(ans => ans.questionId === oldData.questionId);
        if (index !== -1) {
          this.answers.splice(index, 1, newData);
        }
      } else {
        this.answers.push(newData);
      }

      useUserReponse().userResponse.response = this.answers;
      useUserReponse().userResponse.trainingId = this.sectionData.training;
    },
    isDone() {
      this.allQuestionsAnswered && this.amountOfQuestions >= 0 ? this.$emit('sendDone', true) : this.$emit('sendDone', false);
    }
  }
}
</script>